/**
 * @Author: Nidhi Bhatt
 * @Date:   2021-03-09T12:57:43+05:30
 * @Email:  nidhi.bhatt@teksun.com
 * @Last modified by:   Nidhi Bhatt
 * @Last modified time: 2021-03-23T15:42:37+05:30
 * @Copyright: Teksun
 */



/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap'); */
@import "~ngx-smart-modal/ngx-smart-modal";
@import "video.js/dist/video-js.css";

@font-face {
    font-family:'Helvetica';
    src: url('./assets/Helvetica_Font_Linotype/Helvetica/HelveticaLTPro-Black.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family:'Helvetica';
    src: url('./assets/Helvetica_Font_Linotype/Helvetica/HelveticaLTPro-BlackOblique.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family:'Helvetica';
    src: url('./assets/Helvetica_Font_Linotype/Helvetica/HelveticaLTPro-Bold.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family:'Helvetica';
    src: url('./assets/Helvetica_Font_Linotype/Helvetica/HelveticaLTPro-BoldOblique.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family:'Helvetica';
    src: url('./assets/Helvetica_Font_Linotype/Helvetica/HelveticaLTPro-Light.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family:'Helvetica';
    src: url('./assets/Helvetica_Font_Linotype/Helvetica/HelveticaLTPro-LightOblique.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family:'Helvetica';
    src: url('./assets/Helvetica_Font_Linotype/Helvetica/HelveticaLTPro-Oblique.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family:'Helvetica';
    src: url('./assets/Helvetica_Font_Linotype/Helvetica/HelveticaLTPro-Roman.otf') format('opentype');
    font-style: normal;
}

*{margin:0;padding:0;list-style:none;text-decoration:none;box-sizing:border-box;font-family:'Helvetica';}
*,
*::before,
*::after,
*:focus{border:none;outline:none;text-decoration:none}
body{background-color:#F7F9FC;margin:0;}
button, li, a{-moz-transition:all .25s ease-out;-o-transition:all .25s ease-out;-webkit-transition:all .25s ease-out;transition:all .25s ease-out}
button, a{cursor:pointer}
button{background-color:transparent;transition:0.5s;background-size:200% auto;}
button:hover,
button:focus,
a:hover,
a:focus{text-decoration:none;outline:none!important;cursor:pointer}
input[type=file]:focus,
input[type=checkbox]:focus,
input[type=radio]:focus{outline:none}
input[type=password]{letter-spacing:2px;}
input[type=password]::placeholder{letter-spacing:0px;}
input,
textarea{font-family:'Helvetica' !important;color:#021D51 !important;font-size:15px !important;}
input::placeholder,
textarea::placeholder{font-size:15px !important;}
button:hover{background-position:right center;}
a:not([href]):not([tabindex]) {text-decoration:none;}
h1,h2,h3,h4,h5,h6{margin:0;}
.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6{font-family:'Helvetica' !important;}

.app-wrapper .container-fluid{min-height:calc(100% - 64px);}

/* HTTP loader css starts */
.progress-loader{position:fixed;width:100%;height:100%;display:flex;justify-content:center;align-items:center;top:0;z-index:9999;}
.loading-spinner{background-color:#0000001f;position:absolute;width:100%;height:100vh;top:0px;left:0px;align-items:center;justify-content:center;display:flex;z-index:1;}
.loading-spinner img{width:3rem;}
.loading-message{text-align:center;align-self:start;display:none;}
/* HTTP loader css ends */

/* mat drawer starts*/
.mat-drawer-container {background-color:#F2EDF3;}
.mat-sidenav {width:240px;}
.mat-sidenav-custom.mat-sidenav.mat-drawer{z-index:1;}
.mat-toolbar{background-color:#ffffff;box-shadow:0px 0px 24px 0px rgba(0,0,0,0.11);}
.mat-drawer.mat-sidenav{background-color:#002154;}
.ins-sidenav .mat-drawer-backdrop{/*position:fixed;*/}
@media screen and (min-width:1280px) {
  #menu{display:none;}
  .mat-drawer.mat-sidenav{transform:translate3d(0, 0, 0);visibility:visible !important;}
  .mat-drawer-content.mat-sidenav-content{width:calc(100% - 240px);position:absolute;right:0;}
  .mat-drawer.mat-sidenav::before{position:absolute;content:'';left:0px;right:0;top:0;bottom:0;transform:translate3d(0px, 0, 0);z-index:0;transition:background-color 0ms;transition:z-index 0ms;}
  .dialog-open .mat-drawer.mat-sidenav::before{background-color:rgba(0, 0, 0, 0.5);z-index:1;transition:background-color 0ms;transition:z-index 0ms;}
  .overlay{transition:background-color 0ms;}
}
/* mat drawer ends*/
.mat-select-value{color:#002154;}
.mat-sidenav-container.ins-sidenav{background-color:#F7F9FC;min-height:100vh;}
.mat-sidenav-container .mat-sidenav-content{display:flex;flex-direction:column;min-height:100vh;}
.sidenav-toolbar{height:64px;background-color:#333;display:flex;flex-direction:row;}
@media (max-width: 600px) {
  .sidenav-toolbar{height:56px}
}
.mat-nav-list{padding-top:0}
@media (max-width: 600px) {
  .fixed-topnav{position:fixed;top:0;left:0;z-index:2;width:100%!important}
  .mat-drawer-container,
  .mat-drawer{/*padding-top:81px*/}
}
.menu-list-item.mat-list-item .mat-list-item-content{flex:1 1 100%}
.version-info{font-size:8pt;padding:8px;align-self:flex-end}

/* sidemenu starts*/
.ins-logo{padding:.625rem 0;margin:0 0 1rem 0;text-align:center;background-color:#002154;box-shadow:0px 1px 1px 0px #5b5b5b;}
.ins-sidenav .mat-list-base{padding:0;}
.ins-sidenav .mat-list-base .mat-list-item,
.ins-sidenav .mat-list-base .mat-list-option{border-bottom:0px solid #EBEDF2;}
.ins-sidenav .menu-list-item .mat-icon{color:#C9CDD8;height:16px;width:16px;}
.ins-sidenav .menu-list-item .material-icons{font-size:20px;display:flex;margin:0 .75rem 0 0;}
.ins-sidenav .menu-list-item .mat-list-item-content{font-size:14px;color:#C9CDD8;}
.ins-sidenav .mat-list-base .mat-list-item .mat-list-item-content,
.ins-sidenav .mat-list-base .mat-list-option .mat-list-item-content{padding:0 1.2rem;}
.ins-sidenav .mat-list-base .mat-list-item.active .mat-list-item-content,
.ins-sidenav .menu-list-item.active .material-icons{color:#DEE2E6;}
.ins-sidenav .mat-list-item.active{background-color:#0E48CA;}
.ins-sidenav .menu-list-item{color:#C9CDD8;}
/* sidemenu ends*/

/* top menu starts*/
.header.mat-toolbar-row,
.header.mat-toolbar-single-row{height:61px;}
.header .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
.header .mat-stroked-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay{opacity:0;}
.header-matmenu .mat-menu-content:not(:empty){padding:0 0;}
.header-matmenu.mat-menu-panel{min-height:unset;/*right:100%;*//*margin-right:-100px;position:absolute;top:13px;*/min-width:200px;max-width:200px;margin-top:13px;}
.header-matmenu button.mat-menu-item{display:flex;align-items:center;border-bottom:1px solid #eee;font-family:'Helvetica';color:#002154;}
.header-matmenu .material-icons{color:#0E48CA;font-size:20px;margin:0 .5rem 0 0;}
.last-login{display:flex;align-items:center;justify-content:flex-end;font-size:12px;color:#0082cb;}
.last-login .material-icons{font-size:20px;}
.last-login  b,
.last-login .material-icons{color:#c9cdd8;font-weight:400;}
.last-login  b{font-size:13px;margin-right:.5rem;}
.header .d-flex{display:flex;justify-content:flex-end;align-items:center;flex:1;padding:.5rem 0;}
.header .mat-focus-indicator.mat-menu-trigger.mat-button.mat-button-base{/*align-self:flex-end;*/padding:0 0 0 .5rem;}
#menu.mat-focus-indicator.mat-icon-button.mat-button-base .mat-ripple.mat-button-ripple,
.header .mat-focus-indicator.mat-icon-button.mat-button-base .mat-ripple.mat-button-ripple,
.header .mat-ripple.mat-button-ripple{display:none;}

.header-btn{font-size:14px;font-weight:400;margin-left:1.5rem;}

.header-username-btn{display:flex;}
.header-username-btn .mat-button-wrapper{display:flex;align-items:center;}
.header-username-btn .mat-button-wrapper .material-icons{position:relative;top:auto;left:5px;}
.header-username-wrap{display:flex;flex-direction:column;align-items:flex-start;}
#login_name{font-size:14px;line-height:16px;text-transform:none;color:#002154;}
#login_name1{font-size:12px;line-height:18px;color:#002154;}

.notification-btn{position:relative;}
.notification-btn sup{background-color:#E63946;color:#ffffff;font-size:12px;border-radius:100px;padding:4px 3px 2px;margin-left:-6px;top:-0.6em;}

@media screen and (max-width:575px) {
  .header-matmenu.mat-menu-panel{margin-top:5px;/*top:5px;right:100%;margin-right:-100px;*/}
}

@media screen and (max-width:767px){
  #menu.mat-focus-indicator.mat-icon-button.mat-button-base{text-align:left;}
}
.header .mat-button.cdk-keyboard-focused .mat-button-focus-overlay,
.header .mat-button.cdk-program-focused .mat-button-focus-overlay,
.header .mat-icon-button.cdk-keyboard-focused .mat-button-focus-overlay,
.header .mat-icon-button.cdk-program-focused .mat-button-focus-overlay,
.header .mat-stroked-button.cdk-keyboard-focused .mat-button-focus-overlay,
.header .mat-stroked-button.cdk-program-focused .mat-button-focus-overlay,
.header .mat-flat-button.cdk-keyboard-focused .mat-button-focus-overlay,
.header .mat-flat-button.cdk-program-focused .mat-button-focus-overlay{opacity:0;}
.header-wrap{display:flex;}
@media screen and (max-width:575px){
  .mat-toolbar.header.mat-toolbar-single-row{height:auto;}
  .header .mat-focus-indicator.mat-menu-trigger.mat-button.mat-button-base{line-height:20px;margin-bottom:.3rem;padding:0 0 0 8px;font-size:13px;}
  .header-btn.logout-btn{width:20px;overflow:hidden;}
  .header-btn.notification-btn{margin-left:1rem;}
}
@media screen and (max-width:360px){
  .header .d-flex{flex-direction:column;align-items:flex-end;}
}

/* top menu ends*/

/* coverslide starts*/
.coverslide{background-image: url(assets/images/dd-branding-1.jpg);background-position:center;background-repeat:no-repeat;background-size:cover;width:100%;height:100vh;overflow:hidden;}
/* coverslide ends*/

/* authentication starts*/
.auth{position:relative;}
.auth::before{position:absolute;content:'';background-color:#0E48CA;height:9%;top:0;width:100%;z-index:-1;}
.auth, .auth-wrap{min-height:100vh;}
.auth-wrap{display:flex;justify-content:center;align-items:center;margin:0 auto;padding:0 1rem;}
.auth-block{display:flex;align-items:center;flex-direction:column;}
/* .auth-img{margin:0 4rem 0 0;} */
.auth-img img{max-width:85%;height:auto;}
.auth-title{font-size:18px;font-weight:500;margin:1rem 0 2rem;color:#021D51;text-align:center;}
.auth-title span{font-size:14px;font-weight:400;display:block;margin:.2rem 0 0 0;color:#021D51;}
.auth-form{display:flex;flex-direction:column;justify-content:center;align-items:center;}
.auth-form label{color:#021D59;font-size:14px;font-weight:500;display:block;margin:0 0 .4rem;}
.auth-form label sup{color:#E63946;margin-left:2px;}
.auth-block p{color:#021D51;font-size:14px;font-weight:500;text-align:center;}
.auth-block p button{color:#E63946;font-weight:500;}
.auth-block p button:hover{color:#2B2D42;}
.auth-form-bottom{margin:0 0 1rem;display:flex;justify-content:center;width:100%;flex-direction:column;}
.auth-form-bottom-block{display:flex;justify-content:space-between;}
.auth-form-bottom-inner{display:flex;justify-content:flex-start;width:100%;align-items:center;}
.auth-form-bottom-inner .mat-checkbox{display:none;}
.auth-form-bottom button{color:#2B2D42;font-size:14px;font-weight:500;}
.auth-btn{background-color:#0E48CA;color:#ffffff;font-size:16px;font-weight:500;border-radius:6px;height:48px;margin-bottom:1rem;width:250px;}
.auth-btn:hover{background-color:#2B2D42;}
.auth-form-body{display:flex;}
.auth-form-inner-body{margin:0;}
.auth-form input.mat-input-element{background-color:#ffffff;border:1px solid #DEE2E6;padding:.7em;margin-bottom:1.5rem;border-radius:4px;}
.auth-form .mat-form-field-subscript-wrapper{margin-top:0;}
.auth-form-bottom .mat-error{display:flex;justify-content:center;font-size:12px;padding:.5rem 0 0;}
.auth-form .mat-form-field-infix{padding:0;border-top:0 solid transparent;}
.auth-form .mat-form-field-appearance-standard .mat-form-field-underline{display:none;}
.auth-form .mat-form-field{width:350px;}
.auth-form .mat-form-field-wrapper{padding-bottom:0;}
.auth-form .mat-form-field-appearance-standard .mat-form-field-flex{padding-top:0;}
.auth-form .mat-form-field-prefix .mat-icon,
.auth-form .mat-form-field-suffix .mat-icon{position:absolute;top:-50px;right:10px;font-size:100%;color:#6C757D;cursor:pointer;height:16px;width:16px;}

.auth-form .mat-form-field-suffix .mat-icon:nth-child(2){display:none!important;}
.mat-input-element{width:calc(100% - 20px) !important;}
ngx-mat-timepicker input.mat-input-element{width:100% !important;}

.auth-block label.mat-checkbox-layout{margin-bottom:0;/*text-align:center;*/}
.auth-block .mat-checkbox-frame{border-width:1px;border-color:#DEE2E6;background-color:#ffffff;}
.auth-block .mat-ripple.mat-checkbox-ripple.mat-focus-indicator{display:none;}
.auth-block .mat-checkbox-layout .mat-checkbox-label{line-height:unset;white-space:normal;font-size:14px;font-weight:500;color:#2B2D42;}
.auth-block .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.auth-block .mat-checkbox-checked.mat-accent .mat-checkbox-background{background-color:#6792D7;}

.auth-block .mat-checkbox-label strong{color:#0E48CA;font-weight:500;}

.auth-form input:-webkit-autofill,
.auth-form input:-webkit-autofill:hover,
.auth-form input:-webkit-autofill:focus
.auth-form input:-webkit-autofill,
.auth-form textarea:-webkit-autofill,
.auth-form textarea:-webkit-autofill:hover
.auth-form textarea:-webkit-autofill:focus,
.auth-form select:-webkit-autofill,
.auth-form select:-webkit-autofill:hover,
.auth-form select:-webkit-autofill:focus{border:1px solid #DEE2E6 !important;-webkit-text-fill-color:#021D51 !important;box-shadow:0 0 0px 1000px #FFFFFF inset;-webkit-box-shadow:0 0 0px 1000px #FFFFFF inset;transition:background-color 5000s ease-in-out 0s;}

.auth-form-register-body{flex-direction:column;}
.auth-form-register-inner-body .auth-register-form-control:nth-child(2){margin:0 0 0 2rem;}
.auth-form-register-inner-body{display:flex;flex-direction:row;}
.auth-register-form-control{display:flex;flex-direction:column;position:relative;}

.auth-icon{display:flex;justify-content:center;align-items:center;}


@media screen and (max-width:1200px){
  .auth-wrap-register{max-width:calc(100% - 1rem);flex-direction:column;min-height:calc(100vh - 2rem);padding:1rem 0;}
  .auth-wrap-register .auth-img{margin:0 0 2rem 0;}
}

@media screen and (max-width:767px){
  .auth-wrap{max-width:calc(100% - 1rem);flex-direction:column;min-height:calc(100vh - 2rem);padding:1rem 0;}
  .auth-img{text-align:center;margin:0 0 2rem 0;}
  .auth-img img{max-width:calc(60% - 1rem);}
  .auth-form-register-inner-body{flex-direction:column;}
  .auth-form-register-inner-body .auth-register-form-control:nth-child(2){margin:0 0 0 0rem;}
}
@media screen and (max-width:575px){
  .auth-form .mat-form-field{width:300px;}
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background{background-color:#6792D7;}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element{background:#b6c1d4;}

.changepassword-form{margin:1rem 1rem 0;justify-content:flex-start;align-items:flex-start;}
.changepassword-form.auth-form .mat-form-field{border-radius:4px;}
.changepassword-form.auth-form .mat-form-field-prefix .mat-icon,
.changepassword-form.auth-form .mat-form-field-suffix .mat-icon{color:rgba(0,0,0,.26);}
.changepassword-form .auth-btn{width:100%;font-size:14px;background-color:#0E48CA;border-radius:4px;height:unset;line-height:42px;}
.w-100{width:auto;}
@media screen and (max-width:575px){
  .changepassword-form{margin:0 0 0;}
  .changepassword-form.auth-form .mat-form-field{width:100%;}
  .w-100{width:100%;}
}

.changepassword-form .phoneform{width:100%;border-radius:4px;}
/* authentication ends*/

/* dashboard + pages starts */
.title-1{font-size:22px;font-weight:500;color:#021D59;display:flex;align-items:center;text-transform:capitalize;word-break:break-all;margin-bottom:.5rem;}
.title-datetime{font-size:14px;font-weight:400;margin:0 0 0 .3rem;position:relative;top:2px;}
.title-border-bottom{padding:1.25rem 0;border-bottom:1px solid #DEE2E6;}
.title1-sub{display:none;}
@media screen and (max-width:575px){
  .title-1{width:100%;font-size:18px;}
}

.app-box,
.row-eq-height{display:flex;}
.row-eq-flex-direction{flex-direction:column;}
.app-card-100{height:calc(100% - 1rem);}
.app-card{width:100%;padding:1.25rem;background-color:#ffffff;border-radius:6px;box-shadow:0px 0px 24px 0px rgba(0,0,0,0.11);margin:1.25rem 0 0 0;}
.app-card-top{align-items:center;margin-bottom:.75rem;}
.app-card-title{font-size:16px;font-weight:400;color:#021D59;position:relative;word-break:break-all;}
.app-card-icon{margin:0 .45rem 0 0;}

.app-card-icon-right{margin:0 0 0 auto;}
.app-card-settings-icon .app-card-icon-right{display:flex;}
.settings-icon{display:flex;align-items:center;justify-content:center;width:20px;height:20px;}
.settings-icon img{height:13px;}
.app-card-body{margin:0;}
.h-100{min-height:calc(100vh - 160px);}
.app-h-100{min-height:calc(100vh - 183px);}
.d-app-height{display:flex;flex:1;}
iframe{height:calc(100vh - 165px);}
.access-door-body{display:flex;align-items:center;}
.access-door-btn{padding:.45rem 1rem;border-radius:180px;font-size:12px;font-weight:500;margin:0 1rem 0 0;}
.access-door-btn-lg{font-size:20px;padding:.75rem 2rem;}
.access-door-status{font-size:14px;font-weight:700;color:#2B2D42;}
.access-door-status-lg{font-size:16px;}
.door-closed{background-color:#EEFFF2;color:#83E349;display:flex;align-items:center;}
.door-closed .material-icons{color:#14DE4A;margin:0 .3rem 0 0;}
.door-open{background-color:#fce8e8;color:#e63946;font-weight:500;display:flex;align-items:center;}
.door-open .material-icons{color:#e63946;margin-right:.3rem;margin-top:0;}
/* .buzzer-on{background-color:rgba(20, 222, 74, 0.16);color:#14DE4A;border:1px solid #b4eaca;}
.buzzer-off{background-color:#e5eaec;color:#666;border:1px solid #c6c8ca;} */
.buzzer-on{background-color:rgb(252 232 232);color:#e63946;border:1px solid rgb(252 232 232);}
.buzzer-off{background-color:#EEFFF2;color:#83E349;}
.door-open, .door-closed, .buzzer-on, .buzzer-off{padding:.22rem .75rem;}
.pass-key-icon{margin:0 1rem 0 0;}
.pass-key-status{font-size:14px;font-weight:700;color:#2B2D42;}
.pass-key-status b{display:block;color:#E63946;font-weight:700;}
.access-top{margin:0 0 2rem 0;display:flex;align-items:center;}
.access-top-title{font-size:16px;font-weight:700;}
.app-table {width:100%;}
.app-table th{background-color:#F7F9FC;color:#002154;font-size:14px;}
.app-table .mat-sort-header-content{font-size:14px;font-weight:500;color:#002154;white-space:nowrap;}
.app-table th.mat-sort-header-sorted {color:#002154;}
.app-table tr.mat-header-row{height:47px;}
.app-table tr.mat-row:last-child td{border-bottom:none;}
.app-table tr td{border-bottom-color:#DAE2E5;padding-right:1rem;color:#002154;}
.app-table th.mat-header-cell:last-of-type,
.app-table td.mat-cell:last-of-type,
.app-table td.mat-footer-cell:last-of-type{padding-right:0;}
.dashboard-app-table{min-height:unset;}
.app-card-icon .a{fill:#DAE2E5;}
.app-card-icon-title .a{fill:#0E48CA;}
.pass-key-icon-lg img{width:48px;}
.pass-key-status-lg{font-size:21px;}
.pass-key-status-lg b{font-size:18px;}
.btn-delete{color:#E63946;font-size:12px;font-weight:500;background-color:#FEF1F2;padding:.2rem 1rem;border-radius:4px;}
.btn-delete .material-icons{font-size:18px;}
.btn-delivered{color:#1AAC54;font-size:12px;font-weight:500;background-color:#EBFFF3;padding:.2rem 1rem;border-radius:4px;}
.btn-inprocess{color:#EE6C4D;font-size:12px;font-weight:500;background-color:#FFF0E9;padding:.2rem 1rem;border-radius:4px;}
.notification-table tr.mat-row{margin:.5rem 0;}
.notification-block{padding-top:.7rem;padding-bottom:.7rem;padding-left:0rem;}
.notification-message {padding:.75rem 0;background-color:#f5f5f5;cursor:pointer;}
.notification-message-disabled {background-color: rgb(255, 255, 255);}
.notification-message.notification-message-disabled {cursor:default;}
.notification-message span{display:flex;}
.notification-title{font-weight:500;color:#002154;position:relative;}
.notification-title::before{position:absolute;content:"";left:-.75rem;top:.35rem;width:5px;height:5px;background-color:#E63946;border-radius:160px;}
.notification-info{color:#002154;}
.notification-date-info{color:#6792D7;}
.sensor-block{border-radius:6px;box-shadow:0px 0px 24px 0px rgba(0,0,0,0.11);padding:2rem 2rem .5rem 2rem;margin:1.5rem 0 1.5rem 0;min-height:calc(100% - 1.5rem);}
.sensor-block-title{font-size:18px;font-weight:700;position:relative;margin:0 0 2rem 0;}
.sensor-block-title::before{position:absolute;content:'';height:3px;width:40px;background-color:#ffffff;border-radius:10px;bottom:-10px;}
.sensor-block-body{display:flex;}
.sensor-block-num{color:#ffffff;font-size:36px;font-weight:700;}
.sensor-block-num sup{font-size:18px;font-weight:500;line-height:18px;position:relative;top:-15px;}
.sensor-block-num span{font-size:36px;font-weight:300;}
.sensor-body-icon{margin:2rem 0 0 auto;}
.sensor-yellow{background-color:#FFBF69;}
.sensor-blue{background-color:#7BDFF2;}
.sensor-green{background-color:#16DB93;}
.sensor-purple{background-color:#B6CCFE;}

.text-wrap{overflow:hidden;}
.text-title{text-overflow:ellipsis;flex:1;white-space:nowrap;overflow:hidden;}
@media screen and (max-width:575px){
  .text-wrap{overflow:visible;}
  .text-title{text-overflow:ellipsis;flex:unset;white-space:nowrap;overflow:visible;}
}
/* dashboard + pages ends */

.title-wrapper{display:flex;justify-content:space-between;align-items:center;flex-wrap:wrap;color:#002154;}
.add-btn{font-size:13px;background-color:#0E48CA;color:#ffffff;padding:.5rem .75rem .3rem;border-radius:4px;display:flex;align-items:center;white-space:nowrap;}
.add-btn-icon{margin-right:.3rem;display:flex;}
.add-btn span{margin-left:.3rem;}
@media screen and (max-width:991px){
  .title-wrapper{flex-direction:column;justify-content:flex-start;align-items:flex-start;}
  .add-btn {margin-top:.5rem;}
}
@media screen and (max-width:767px){
  .title-wrapper{flex-direction:column;justify-content:flex-start;align-items:flex-start;}
}
@media screen and (max-width:575px){
  .title-wrapper{flex-direction:column;align-items:flex-start;}
  .add-btn span{display:none;}
}
.delete-btn{font-size:13px;background-color:#0E48CA;color:#ffffff;padding:.3rem .75rem;border-radius:4px;display:flex;align-items:center;white-space:nowrap;}
.delete-btn-icon{margin-right:.3rem;display:flex;}
.delete-btn span{margin-left:.3rem;}
@media screen and (max-width:575px){
  .delete-btn{margin-bottom:.5rem;}
  .delete-btn span{display:none;}
  .delete-btn span + span{display:block;}
}

.grant-wrap{display:flex;justify-content:flex-end;}
.grant-btn{margin-right:.5rem;}
@media screen and (max-width:991px){
  .grant-wrap{width:100%;justify-content:flex-start;flex-wrap:wrap;}
}

.settings-btn{margin-left:0rem;font-size:13px;background-color:#0E48CA;color:#ffffff;padding:.3rem .75rem;border-radius:4px;display:flex;white-space:nowrap;align-items:center;}
.settings-btn-icon{margin-right:.3rem;}
.settings-btn span{margin-left:.3rem;}

@media screen and (max-width:991px){
  .grant-wrap .grant-btn{margin:0 .5rem .5rem 0;}
  .grant-wrap .settings-btn{margin:0 .5rem .5rem 0;}
}
@media screen and (min-width:1201px) and (max-width:1600px){
  .app-card-list-wrapper .col-xl-3{flex:0 0 33.33333%;max-width:33.33333%;}
}
.enclosurelist-card-wrapper{display:flex;align-items:center;justify-content:space-between;}
.enclosurelist-card-top{display:flex;justify-content:space-between;align-items:flex-start;}
.enclosurelist-card-top .app-card-title{font-weight:500;}
.enclosurelist-card-wrapper .app-card-title{font-size:13px;font-weight:500;min-height:44px;}
.enclosurelist-card-top .app-card-title span{font-weight:300;color:#021D59;}
.enclosurelist-card-wrapper .app-card-title span{font-weight:500;color:#021D59;}
.enclosurelist-card-top .app-card-title span,
.enclosurelist-card-wrapper .app-card-title span{display:flex;font-size:13px;line-height:22px;}
.enclosurelist-status{display:flex;justify-content:center;align-items:center;margin-right:1rem;font-size:13px;font-weight:500;line-height:38px;flex:1;}
.access-card{padding:.2rem .5rem .25rem;border-radius:4px;font-size:12px;white-space:nowrap;}
.primary-access-size{font-weight:600;letter-spacing:0.5px;}
.primary-access{background-color:#83e3492b;color:#83E349;}
.secondary-access{background-color:#FFF0E9;color:#FF914F;}
.temporary-access{background-color:#F1F1FF;color:#453EAB;}
.onetime-access{background-color:#F3F4FA;color:#03071E;}
.deleted-access{background-color:#FEF1F2;color:#E63946;}
.edited-access{background-color:#f8f3d6;color:#967132;}
.duration-card{white-space:nowrap;padding:.3rem .5rem;display:inline-flex;flex-direction:column;margin:.5rem 0;}
.duration-card strong{margin:0 .3rem 0 0;font-weight:500;color:#6c757d;}
.duration-card.primary-access,
.duration-card.secondary-access,
.duration-card.temporary-access,
.duration-card.onetime-access{background-color:rgba(0, 0, 0, 0.05);font-size:13px;}
.enclosurelist-detail-btn{font-size:13px;font-weight:600;letter-spacing:0.5px;background-color:#6792d71f;padding:.6rem .7rem .4rem;border-radius:4px;color:#6792D7;}
.enclosurelist-delivery-status{font-size:13px;font-weight:500;border:1px solid #F1F1FF;border-radius:4px;height:28px;line-height:26px;padding:0 1rem;}
.delivered-status{color:#14DE4A;}
.shipped-status{color:#453EAB;}
.enclosurelist-card .app-card-body{border-top:1px solid #DAE2E5;padding-top:1rem;}
.enclosurelist-card.app-card{background-image:url(assets/images/intersection.png);background-repeat:no-repeat;background-position:top right;background-size:58px 57px;}

.table-span{font-weight:500;color:#03071E;white-space:nowrap;}

.battery-status-icon{margin:0 1rem 0 0;}
.battery-status{font-size:20px;font-weight:700;color:#2B2D42;}
.battery-new-icon img{height:30px;}

.shared-access-icon img,
.forward-delivery-icon img{height:32px;}
.heater-status-icon img{height:32px;width:auto;}

.modal-title{font-size:18px;font-weight:500;background-color:#FAFAFA;padding:.75rem 1.35rem;border-radius:6px 6px 0 0;color:#021D59;}
.modal-title-encl{padding:1rem 1rem 1rem;background-color:#f5f5f5;}
.modal-subtitle-encl{display:flex;align-items:center;font-size:13px;font-weight:500;color:#002154;}
.modal-subtitle-encl img{height:16px;margin:0 .68rem 0 0;}
.modal-subtitle-encl-name{font-size:14px;font-weight:500;color:#6792D7;display:flex;padding-left:1.58rem;margin-top:.3rem;word-break:break-all;}
.modal{height:unset;}
.modal .nsm-content{width:100%;padding:0;border-radius:6px;}
.modal .nsm-dialog-btn-close{top:5px;right:12px;}
.modal .nsm-dialog-btn-close svg{width:12px;height:12px;}
.modal-form{padding:1rem;}
.modal-input{display:flex;}
.modal-input em{margin-right: 6px;}
.modal-input i{margin-right:.5rem;}
.modal-input label{flex:1;margin-bottom:0;font-size:13px;font-weight:500;padding-top:.5rem;color:#021D59;}
.modal-input mat-form-field{flex:1.75;}
.modal-input .mat-form-field-appearance-standard .mat-form-field-flex{padding-top:0;}
.modal-input .mat-form-field-infix{padding:0em 0;border-top:0em solid transparent;}
.modal-input input.mat-input-element{padding:.5em 0;margin-top:0;font-size:14px;font-weight:500;height:38px;}
.modal-input .mat-form-field-ripple{display:none;}
.modal-input .mat-form-field-subscript-wrapper{margin-top:0;top:calc(100% - 20px);}
.modal-input label sup{color:#F44336;margin-left:2px;}
.modal-form .add-btn{margin:.5rem 0;display:flex;align-items:center;}
.modal-input textarea.mat-input-element{font-size:14px;font-weight:500;resize:none;height:68px;background-color:#FAFAFA;border:1px solid #DAE2E5;border-radius:2px;padding:.5rem;margin-top:.3rem;}
.modal-btn-update{position:absolute;margin-top:24.7rem;}
.modal-input-cursor label{cursor:pointer;}

.phoneform-relative{position:relative;}
.phoneform-relative .ngx-error{bottom:-15px;left:0;}
.phoneform{border:1px solid #DEE2E6;padding:.5em 0 .5em .5em;background-color:#fff;margin:0 0 1.5rem 0;width:350px;border-radius:4px;}
.phoneform .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag{background-color:rgba(0, 0, 0, 0)!important;}
/* .phoneform .iti__selected-flag.dropdown-toggle .iti__flag, */
.phoneform .dropdown-menu.country-dropdown.show{width:220px;}
.phoneform .dropdown-menu.country-dropdown.show,
.phoneform .iti__arrow{display:none!important;}
.phoneform .iti__selected-flag{padding:0 0 0 0!important;}
.phoneform .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 .iti__selected-flag,
.phoneform .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 .iti__selected-flag,
.phoneform .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 .iti__selected-flag,
.phoneform .iti.separate-dial-code .iti__selected-flag{width:40px!important;}

.phoneform .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 input,
.phoneform .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 input,
.phoneform .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input,
.phoneform .iti.separate-dial-code input{padding-left:45px!important;padding-right:0px !important;width:100%;}

.phoneform .iti--allow-dropdown input,
.phoneform .iti--allow-dropdown input[type=text],
.phoneform .iti--allow-dropdown input[type=tel],
.phoneform .iti--separate-dial-code input,
.phoneform .iti--separate-dial-code input[type=text],
.phoneform .iti--separate-dial-code input[type=tel]{padding-left:30px;}

.ngx-error{position:absolute;bottom:10px;line-height:13px;}
.ngx-error strong{color:#F44336;font-size:12px;font-weight:400;}

@media screen and (max-width:575px){
  .modal-input{flex-direction:column;}
  .phoneform{width:300px;}
}

.phoneform.ota-phoneform{background-color:#fafafa;padding:.4rem .5rem;}
.ota-phoneform{width:100%;margin-top:.3rem;}
.phoneform.ota-phoneform .iti--allow-dropdown input,
.phoneform.ota-phoneform .iti--allow-dropdown input[type=text],
.phoneform.ota-phoneform .iti--allow-dropdown input[type=tel],
.phoneform.ota-phoneform .iti--separate-dial-code input,
.phoneform.ota-phoneform .iti--separate-dial-code input[type=text],
.phoneform.ota-phoneform .iti--separate-dial-code input[type=tel]{padding-left:30px;width:100%;background-color:#fafafa;}

.modal-input input:-webkit-autofill,
.modal-input input:-webkit-autofill:hover,
.modal-input input:-webkit-autofill:focus
.modal-input input:-webkit-autofill,
.modal-input textarea:-webkit-autofill,
.modal-input textarea:-webkit-autofill:hover
.modal-input textarea:-webkit-autofill:focus,
.modal-input select:-webkit-autofill,
.modal-input select:-webkit-autofill:hover,
.modal-input select:-webkit-autofill:focus {
  border:1px solid #ffffff !important;
  -webkit-text-fill-color: inherit !important;
  box-shadow: 0 0 0px 1000px #FFFFFF inset;
  -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
  transition: background-color 5000s ease-in-out 0s;
}

.enclosuresettings-modal.nsm-dialog,
.forwarddelivery-modal.nsm-dialog,
.addenclosure-modal.nsm-dialog{max-width:320px;}
.addenclosure-form {position:relative; padding-bottom:3.5rem}
.addenclosure-form .modal-input:nth-child(1) i img{width:16px;height:16px;}
.addenclosure-form .modal-input:nth-child(2) i img,
.addenclosure-form .modal-input:nth-child(3) i img{height:20px;}
.addenclosure-form .modal-input{flex-direction:column}
.addenclosure-form .modal-input-space{margin-left:0.7rem;}
.addenclosure-form .modal-input .mat-form-field-appearance-standard .mat-form-field-underline{display:none;}
.addenclosure-form .modal-input input.mat-input-element{background-color:#FAFAFA;border:1px solid #DAE2E5;border-radius:2px;padding:0 .5rem;margin-top:.3rem;}

.addenclosure-form .modal-input input:-webkit-autofill,
.addenclosure-form .modal-input input:-webkit-autofill:hover,
.addenclosure-form .modal-input input:-webkit-autofill:focus
.addenclosure-form .modal-input input:-webkit-autofill,
.addenclosure-form .modal-input textarea:-webkit-autofill,
.addenclosure-form .modal-input textarea:-webkit-autofill:hover
.addenclosure-form .modal-input textarea:-webkit-autofill:focus,
.addenclosure-form .modal-input select:-webkit-autofill,
.addenclosure-form .modal-input select:-webkit-autofill:hover,
.addenclosure-form .modal-input select:-webkit-autofill:focus {
  border:1px solid #DAE2E5 !important;
  -webkit-text-fill-color: inherit !important;
  box-shadow: 0 0 0px 1000px #FAFAFA inset;
  -webkit-box-shadow: 0 0 0px 1000px #FAFAFA inset;
  transition: background-color 5000s ease-in-out 0s;
}
@media screen and (max-width:575px){
  .modal-btn-update{
    margin-top:29rem;
  }
  .addenclosure-form .modal-input-space{
    margin-left:0rem;
  }
  .addenclosure-form .modal-input-spacebetween{
    margin-left:0.7rem;
  }
}
@media screen and (max-width:480px){
  .modal-btn-update{
    margin-top:56rem;
  }
  .addenclosure-form .modal-input-spacebetween{
    margin-left:0rem;
  }

}
.enclosuresettings-form label{display:flex;align-items:center;padding-top:0;}
.enclosuresettings-form .modal-input i{width:32px;height:20px;display:inline-flex;margin-right:0;}
/* .enclosuresettings-form .modal-input:nth-child(1) i img,
.enclosuresettings-form .modal-input:nth-child(2) i img,
.enclosuresettings-form .modal-input:nth-child(3) i img{width:auto;height:auto;} */
.enclosuresettings-form .modal-input {margin-bottom:1rem;padding-bottom:1rem;border-bottom:1px solid #DAE2E5;}
.enclosuresettings-form .modal-input:last-child{margin-bottom:0;padding-bottom:0;border-bottom:none;}
.enclosuresettings-form .modal-input label{flex:1;}
.enclosuresettings-inner-form .modal-input:last-child{margin-bottom:1rem;padding-bottom:1rem;border-bottom:1px solid #DAE2E5;}

.forwarddelivery-form .modal-input{flex-direction:column;}
.forwarddelivery-form .modal-input .mat-form-field-appearance-standard .mat-form-field-underline{display:none;}
.forwarddelivery-form .modal-input input.mat-input-element{background-color:#FAFAFA;border:1px solid #DAE2E5;border-radius:2px;padding:0 .5rem;margin-top:.3rem;color:#2B2D42;}

.modal-input-subtext{font-size:14px;background-color:#FAFAFA;border:1px solid #DAE2E5;border-radius:2px;padding:.5rem;margin:.3rem 0 1rem 0;color:#002154;min-height:38px;}

.forwarddelivery-form .modal-input input:-webkit-autofill,
.forwarddelivery-form .modal-input input:-webkit-autofill:hover,
.forwarddelivery-form .modal-input input:-webkit-autofill:focus
.forwarddelivery-form .modal-input input:-webkit-autofill,
.forwarddelivery-form .modal-input textarea:-webkit-autofill,
.forwarddelivery-form .modal-input textarea:-webkit-autofill:hover
.forwarddelivery-form .modal-input textarea:-webkit-autofill:focus,
.forwarddelivery-form .modal-input select:-webkit-autofill,
.forwarddelivery-form .modal-input select:-webkit-autofill:hover,
.forwarddelivery-form .modal-input select:-webkit-autofill:focus {
  border:1px solid #DAE2E5 !important;
  -webkit-text-fill-color: inherit !important;
  box-shadow: 0 0 0px 1000px #FAFAFA inset;
  -webkit-box-shadow: 0 0 0px 1000px #FAFAFA inset;
  transition: background-color 5000s ease-in-out 0s;
}

@media screen and (max-width:575px){
  .enclosuresettings-form .modal-input{flex-direction:row;}
}

.forwarddeliverylist-card-top{margin-bottom:0;}
.forwarddeliverylist-card .app-card-title{font-size:16px;text-transform:capitalize;width:100%;}
.forwarddeliverylist-card .app-card-title span{display:flex;font-size:14px;font-weight:400;color:#021D59;margin-top:.3rem;text-transform:lowercase;}
.forwarddeliverylist-id{margin-bottom:0;margin-top:.3rem;background-color:#E5FCEB;color:#14DE4A;border-radius:4px;padding:.4rem .75rem .2rem;font-size:13px;font-weight:400;}
.forwarddeliverylist-id-status{margin-top:8px;margin-bottom:0;border-radius:4px;padding:.3rem .4rem ;font-size:13px;font-weight:400;display:flex;align-items:center;justify-content:center;}
.forwarddeliverylist-id-status .material-icons{font-size:16px;position:relative;top:0px;font-weight:700;margin-right:.2rem;}

.accepted{background-color:#dcfdee;color:#37ce8a;}
.rejected{background-color:#ecf0f3;color:#37474F;}
.pending{background-color:#f1ecff;color:#7C4DFF;}
.completed{background-color:#dcfdee;color:#37ce8a;}
.deleted{background-color:#FCE8E8;color:#E63946;}

.auto-expired{background-color:#ffe9e7;color:#fc544b;}
.default-expired{background-color:#FCE8E8;color:#E63946;}
.ongoing{background-color:#e4f5fd;color:#3abaf4;}
.auto-cancelled{background-color:#FCE8E8;color:#E63946;}

.enclosurelist-card-body{margin:.5rem 0 0 0;}
.forwarddeliverylist-date{font-size:12px;font-weight:500;display:flex;justify-content:space-between;flex-wrap:wrap;flex-direction:column;background-color:#f9f9f9;padding:0 0 0 0;border-radius:4px;}
.forwarddeliverylist-date-wrap{margin:.75rem 1rem;display:flex;align-items:center;flex-wrap:wrap;}
.forwarddeliverylist-date-wrap span{display:flex;justify-content:center;align-items:center;margin-right:.3rem;}
.forwarddeliverylist-date-wrap strong{font-weight:400;width:30px;}
.forwarddeliverylist-date-wrap img{width:14px;margin-right:.3rem;}

.forwarddeliverylist-requested-date-wrap{display:flex;align-items:center;padding:.75rem 1rem;background-color:#e9ecef;flex-wrap:wrap;}
.forwarddeliverylist-requested-date-wrap span{display:flex;justify-content:center;align-items:center;margin-right:.3rem;}
.forwarddeliverylist-requested-date-wrap strong{font-weight:400;width:auto;}
.forwarddeliverylist-requested-date-wrap img{width:14px;margin-right:.3rem;}

.clr-green{color:#14DE4A;}
.clr-red{color:#F44336;}
.clr-info{color:#3abaf4;}

.delete-modal .modal-text{font-size:16px;font-weight:500;padding:1.5rem 1rem;background-color:#eff4f7;border-radius:4px 4px 0 0;margin:0;color:#002154;}
.delete-form{display:flex;justify-content:center;flex-direction:column;}
.delete-form .modal-input{flex-direction:column;}
.delete-form .modal-input .mat-form-field-appearance-standard .mat-form-field-underline{display:none;}
.delete-form .modal-input input.mat-input-element{background-color:#FAFAFA;border:1px solid #DAE2E5;border-radius:2px;padding:0 .5rem;margin-top:.3rem;}
.delete-form .modal-input input:-webkit-autofill,
.delete-form .modal-input input:-webkit-autofill:hover,
.delete-form .modal-input input:-webkit-autofill:focus
.delete-form .modal-input input:-webkit-autofill,
.delete-form .modal-input textarea:-webkit-autofill,
.delete-form .modal-input textarea:-webkit-autofill:hover
.delete-form .modal-input textarea:-webkit-autofill:focus,
.delete-form .modal-input select:-webkit-autofill,
.delete-form .modal-input select:-webkit-autofill:hover,
.delete-form .modal-input select:-webkit-autofill:focus {
  border:1px solid #DAE2E5 !important;
  -webkit-text-fill-color: inherit !important;
  box-shadow: 0 0 0px 1000px #FAFAFA inset;
  -webkit-box-shadow: 0 0 0px 1000px #FAFAFA inset;
  transition: background-color 5000s ease-in-out 0s;
}

.delete-bottom{margin-top:1rem}
.delete-btns{padding:.3rem .75rem;border-radius:4px;margin-bottom:1rem;font-size:14px;font-weight:500;}
.yes-btn{background-color:#0E48CA;color:#FFFFFF;}
.no-btn{background-color:#FFFFFF;color:#0E48CA;border:1px solid #0E48CA;}

.card-form{padding:0;display:flex;justify-content:center;flex-direction:column;}
.card-form .modal-input{flex-direction:column;}
.card-form .modal-input .mat-form-field-appearance-standard .mat-form-field-underline{display:none;}
.card-form .modal-input input.mat-input-element{background-color:#FAFAFA;border:1px solid #DAE2E5;border-radius:2px;padding:0 .5rem;margin-top:.3rem;}

/* open close switch css starts*/
.button-cover,
.knobs,
.layer{position:absolute;top:0;right:0;bottom:0;left:0}
.button{position:relative;top:0;width:56px;height:22px;margin:0px auto 0;overflow:hidden}
.button.r,
.button.r .layer{border-radius:160px}
.button.b2{border-radius:160px}
.checkbox{position:relative;width:100%;height:100%;padding:0;margin:0;opacity:0;cursor:pointer;z-index:3}
.knobs{z-index:2}
.layer{width:100%;background-color:rgba(20, 222, 74, 0.16);transition:.3s ease all;z-index:1}
#button-12 .knobs:before,
#button-12 .knobs:after,
#button-12 .knobs span,
#button-12 .knobs span:before,
#button-12 .knobs span:after{position:absolute;top:5px;font-size:10px;font-weight:500;text-align:center;line-height:1;border-radius:160px;transition:.3s ease all}
#button-12 .knobs:before{content:'Open';left:2px;color:#14DE4A;}
#button-12 .knobs:after{content:'';right:-15px;color:#F44336;}
#button-12 .knobs:before,
#button-12 .knobs:after{width:17px;height:17px;padding:2px 3px;z-index:1}
#button-12 .knobs span{display:inline-block;z-index:2}
#button-12 .knobs span,
#button-12 .knobs span:before,
#button-12 .knobs span:after{width:17px;height:17px;padding:2px 3px}
#button-12 .knobs span:before,
#button-12 .knobs span:after{top:-2px}
#button-12 .knobs span:before{left:-28px;background-color:#F44336;content:'';}
#button-12 .knobs span:after{right:-35px;background-color:#14DE4A;content:'';}
#button-12 .checkbox:checked + .knobs span:before{left:4px;}
#button-12 .checkbox:checked + .knobs span:after{right:-74px}
#button-12 .checkbox:checked ~ .layer{background-color:#fcebeb}
#button-12 .checkbox:checked + .knobs::before{content:'Close';color:#F44336;left:22px;}
/* open close switch css ends*/

/* resetpassword css starts*/
.resetpassword .auth-title{margin:1rem 0 .3rem;}
.resetpassword .auth-subtitle{color:#6C757D;font-size:13px;text-align:center;max-width:300px;}
.resetpassword .auth-email{display:flex;align-items:center;justify-content:center;color:#e63946;font-weight:500;}
.resetpassword .auth-form-body{flex-direction:column;align-items:center;justify-content:center;}
.resetpassword .auth-form-inner-body{display:flex;flex-direction:column;}
.resetpassword .auth-form-bottom{flex-direction:column;}
.resetpassword .auth-form-bottom-text{display:flex;margin:1rem 0 0 0;text-align:center;flex-direction:column;width:100%;justify-content:center;}
.resetpassword .auth-form-bottom-text a{color:#e63946;}
.resetpassword .auth-form-bottom button.changepass-btn{background-color:#E63946;color:#ffffff;font-size:14px;font-weight:500;border-radius:6px;height:38px;margin-bottom:0rem;width:auto;padding:0 1rem;}
.resetpassword.auth::before{height:50%;}
.resetpassword .auth-block{background-color:#ffffff;border-radius:6px;padding:2rem 2rem 1rem;box-shadow:0px 0px 24px 0px rgba(0,0,0,0.11);}
.resetpassword .auth-form .mat-form-field-suffix .mat-icon:nth-child(2){display:block!important;}
.resetpassword .auth-form .mat-form-field-suffix .mat-icon.pass-info{top:-12px;right:0;color:#e63946;}
.error {color:#e63946;font-size:12px;}
.gps-error{color:#e63946;font-size:18px;font-weight:600;}
.no-data-delivery{display:flex;flex-direction:column;justify-content:center;align-items:center;margin:2rem 0 0 0;}
.no-data-wrap{display:flex;flex-direction:column;flex-wrap:wrap;justify-content:center;align-items:center;padding:1rem;margin:1rem 0 0 0;background-color:#ffffff;border-radius:6px;box-shadow:0px 0px 24px 0px rgba(0,0,0,0.11);}
.no-data-title{font-size:16px;padding:1rem 0 0 0;color:#002154;}
.gps-error-block{display:flex;margin:1rem 0 0 0;flex-direction:column;}
.gps-ok-btn{background-color:#e63946;color:#ffffff;padding:.325rem .625rem;width:90px;margin:1rem auto 2rem;border-radius:4px;font-size:13px;}
.no-data-image img{height:60px;}
.no-data{padding:1rem;display:flex;background-color:#ffffff;border-radius:6px;box-shadow:0px 0px 24px 0px rgba(0,0,0,0.11);}
.confirm-error{position:absolute;bottom:8px;}
.mat-tooltip,.mat-tooltip-handset{font-size:12px;}
.resetpassword .back-btn{padding:0;margin:0;display:inline-flex;background-color:unset;font-size:15px;font-weight:500;color:#2B2D42;}
.resetpassword .auth-form .mat-form-field{margin-bottom:.55rem;}
@media screen and (max-width: 575px){
  .resetpassword .auth-block{justify-content:center;}
  .resetpassword .auth-form {width:100%;}
  .resetpassword .auth-form-body,
  .resetpassword .auth-form-inner-body,
  .resetpassword .auth-form .mat-form-field{width:100%;margin-bottom:.75rem;}
  .resetpassword .auth-form-bottom-inner{/*flex-direction:column;*/}
  .resetpassword .back-btn{/*margin:1rem 0 0;*/}
  .no-data{justify-content:center;}
}
/* resetpassword css ends*/

/* verified css starts*/
.resetpassword.verified .auth-block{width:520px;}
.resetpassword.verified .auth-title{font-size:32px;}
.resetpassword.verified .auth-subtitle{font-size:18px;max-width:unset;}
.resetpassword.verified .auth-icon .material-icons{color:#14DE4A;font-size:50px;}
.resetpassword.verified .auth-verify-title{font-size:15px;text-align:center;}
.resetpassword.verified .auth-email{font-weight:400;}
@media screen and (max-width: 575px){
  .resetpassword.verified .auth-block{width:100%;}
}

.resetpassword.verified .status-unverified .icon-red{color:#E63946;}
/* verified css ends*/

.grant-form .modal-input {width:100%;display:flex;justify-content:center;align-items:center;}
.grant-form .modal-input label{cursor:pointer;}

.modal-input .mat-button.cdk-keyboard-focused .mat-button-focus-overlay,
.modal-input .mat-button.cdk-program-focused .mat-button-focus-overlay,
.modal-input .mat-icon-button.cdk-keyboard-focused .mat-button-focus-overlay,
.modal-input .mat-icon-button.cdk-program-focused .mat-button-focus-overlay,
.modal-input .mat-stroked-button.cdk-keyboard-focused .mat-button-focus-overlay,
.modal-input .mat-stroked-button.cdk-program-focused .mat-button-focus-overlay,
.modal-input .mat-flat-button.cdk-keyboard-focused .mat-button-focus-overlay,
.modal-input .mat-flat-button.cdk-program-focused .mat-button-focus-overlay{opacity:0;}

.modal-input .mat-form-field-prefix,
.modal-input .mat-form-field-suffix{top:5px;right:0;position:absolute;}

.modal-input .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.modal-input .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-datepicker-toggle-default-icon{fill:#A6A6A6;width:18px;}

.mat-calendar-body-selected{background-color:#0E48CA;}
.mat-calendar-body-disabled>.mat-calendar-body-selected{background-color:#0e48ca54;}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary{color:#0E48CA;}

.door_open{background-color:#fce8e8;color:#e63946;width:37px;height:22px;line-height:22px;padding:0 0 0 .5rem;border-radius:160px;position:relative;font-size:10px;font-weight:500;display:flex;}
.door_open::after{position:absolute;content:'';width:16px;height:16px;background-color:#D62828;border-radius:160px;top:3px;right:2px;}

.door_closed{background-color:#EEFFF2;color:#83E349;width: 37px;height:22px;line-height:22px;padding:0 .5rem 0 0;border-radius:160px;position:relative;font-size:10px;font-weight:500;display:flex;justify-content:flex-end;}
.door_closed::after{position:absolute;content:'';width:16px;height:16px;background-color:#83E349;border-radius:160px;top:3px;left:2px;}

.door_disabled{background-color:#e9ecef;color:#b7b9ba;width: 37px;height:22px;line-height:22px;padding:0 .5rem 0 0;border-radius:160px;position:relative;font-size:10px;font-weight:500;display:flex;justify-content:flex-end;}
.door_disabled::after{position:absolute;content:'';width:16px;height:16px;background-color:#b7b9ba;border-radius:160px;top:3px;left:2px;}

.toast-container{/*height:100vh;display:flex;align-items:center;*/}
.toast-container .ngx-toastr:hover{box-shadow:0 0 12px #999999;}
.toast-message {font-size:14px;font-weight:400;font-family:'Helvetica';}
button.toast-close-button{position:absolute;right:10px;top:0px;display:none;}
.toast-container .ngx-toastr{display:flex;align-items:flex-start;background-position:15px 15px;background-size:20px;}
@media screen and (min-width: 241px) and (max-width: 480px){
  button.toast-close-button{display:none;}
}

.counts-card {cursor:pointer;}
.counts-card .app-card-title{position:relative;}
.counts-card .app-card-title::before{position:absolute;content:'';height:3px;bottom:-1rem;background-color:#ffffff;width:2rem;}
.counts{font-size:32px;font-weight:700;color:#ffffff;margin:2rem 0 0;}
.counts-green{color:#14DE4A;}
.counts-orange{color:#EE6C4D;}
.counts-purple{color:#453EAB;}
.counts-gray{color:#8D99AE;}
.counts-bgyellow{background-color:#FFBF69;}
.counts-bgblue{background-color:#7BDFF2;}
.counts-bggreen{background-color:#16DB93;}
.counts-bgpurple{background-color:#B6CCFE;}

.no-enclosure{min-height:204px;}
.no-enclosure .enclosurelist-card.app-card{background-image:unset;}
.cursor {cursor:pointer;}
.app-delete-card{background-image:url(assets/images/intersection.png);background-repeat:no-repeat;background-position:top right;background-size:58px 57px;}
.forwarddeliverylist-card .app-card-title .delete-icon-btn span.material-icons{margin:0 0 0 0;font-size:22px;color:#F44336;}
.encl-name-title{color:#ec0827;font-size:18px;}

.forwarddeliverylist-card .app-card-title .warning-icon-btn span.material-icons{margin:0 .2rem 0 0;font-size:22px;color:#ffa426;}

.access-title{font-size:13px;font-weight:400;border-radius:4px;padding:.3rem .5rem;display:inline-flex;margin:0 0 0 1rem;}
.access-green{color:#83E349;background-color:#EEFFF2;}
.access-orange{color:#FF914F;background-color:#FFF0E9;}
.access-purple{color:#453EAB;background-color:#F1F1FF;}
.access-gray{color:#8D99AE;background-color:#f5f5f5;}

.more-btn{display:inline-flex;align-items:center;margin-left:auto;color:#0E48CA;font-size:12px;font-weight:500;background-color:#dce4f8;border-radius:4px;padding:.2rem .4rem;}
.more-btn .material-icons{font-size:13px;
  transform: rotate(100deg);
  -webkit-transform: rotate(100deg);
  -moz-transform: rotate(100deg);}

.app-card-info{border-radius:4px;margin-bottom:1rem;padding:.5rem 1rem;display:flex;align-items:center;}
.app-card-info:last-child{margin-bottom:0;}
.app-card-info label{font-size:13px;font-weight:500;margin:0 .5rem 0 0;color:#021D59;display:flex;align-items:center;}
.app-card-info span{font-size:13px;font-weight:400;color:#000000;word-break:break-all;}
.app-card-info .access-door-btn{margin:0 0 0 0;}
.app-card-info-bt{margin-bottom:1rem;}

.app-card-info.address-card-info{align-items:flex-start;}

@media screen and (max-width: 575px){
  .app-card-info{flex-direction:column;justify-content:flex-start;align-items:flex-start;padding:.85rem 1rem 1rem;}
  .app-card-info .access-door-btn{margin:.5rem 0 0;}
  .app-card-info span{margin:.5rem 0 0;}
}

.app-card-info.app-card-dates label{display:flex;flex-wrap:wrap;}
.start-date,.end-date{display:flex;width:100%;font-size:13px;color:#000000;padding:.5rem 0 0 0;}

.back-btn{font-size:13px;font-weight:400;color:#2B2D42;background-color:#eff1f4;position:relative;margin:0 .65rem 0 0;display:inline-flex;align-items:center;padding:.2rem .3rem .2rem .3rem;border-radius:4px}
/* .back-btn::after{position:absolute;content:'';right:-20px;top:5px;background-color:#2B2D42;width:4px;height:4px;border-radius:160px;} */
.back-btn .material-icons{font-size:18px;}

.title-access{margin-left:auto;}
.title-access .access-card{font-size:13px;font-weight:400;}

.sub-title-wrapper{display:flex;justify-content:flex-start;align-items:center;flex-wrap:wrap;margin:0 0 0;border-radius:4px;/*background-color:#eff1f4;*/padding:0 0;}
.encl-name-subtitle{font-size:14px;font-weight:600;letter-spacing:0.5px;color:#6792D7;margin:0 1rem 0 0;}
@media screen and (max-width: 991px){
  .sub-title-wrapper{margin:1rem auto 0 0;}
}

.response .auth-title{display:flex;justify-content:center;font-size:22px;line-height:30px;margin:0 0 1.5rem;position:relative;text-transform:capitalize;background-color:#F7F5F6;width:100%;padding:1.5rem 2rem;border-radius:4px 4px 0 0;}
/* .response .auth-title::before{position:absolute;content:'';background-color:#D1D3D4;height:2px;width:50px;bottom:-1rem;left:0;right:0;margin:0 auto;} */
.response .auth-text {font-size:16px;font-weight:400;color:#6C757D;margin:0 0 1.5rem 0;position:relative;}
.response .auth-text::before{position:absolute;content:'';background-color:#D1D3D4;height:2px;width:50px;bottom:-.5rem;left:0;right:0;margin:0 auto;}
.response .auth-text b{display:flex;justify-content:center;color:#231F20;font-weight:700;}
.response .auth-subtext{font-size:16px;font-weight:400;color:#6C757D;margin:1rem 0 1.5rem 0;}
.response .auth-subtext b{display:inline-flex;justify-content:center;background-color:#EEFFF2;color:#14DE4A;font-weight:400;padding:.2rem .75rem;}
.response .auth-duration {margin-bottom:.3rem;}
.response .auth-duration strong{display:inline-flex;justify-content:center;color:#231F20;font-size:14px;}
.response .auth-block{padding:0rem 0rem 2.5rem 0;width:520px;}
.auth-subinner-text{font-weight:500;}

.res-pending{color:#ff7043;}
.res-accepted,.res-completed{color:#4caf50;}
.res-rejected,.res-deleted{color:#e53935;}

@media screen and (max-width: 575px){
  .response .auth-title{padding:1.5rem 1rem;}
  .response .auth-block{width:100%;}
}

button:disabled{background:#d4d1d1;cursor:default;}

/* reminder select panel css starts */

.mat-select-panel.mat-primary.reminder-select-panel{margin:2rem 0 0 0;position:relative;right:10px;}
.mat-select-panel.mat-primary.reminder-select-panel .mat-optgroup-label,
.mat-select-panel.mat-primary.reminder-select-panel .mat-option,
.reminder-select .mat-select-value-text{font-size:13px;font-weight:500;width:100%;}
.reminder-select .mat-select-value-text span{position:relative;top:-2px;}
.reminder-select .mat-select-value{text-align:right;color:#002154;}
.reminder-select.led-select .mat-select-value-text mat-select-trigger span{top:0;}
.led-select-panel.reminder-select-panel.mat-primary .mat-option.mat-selected:not(.mat-option-disabled){color:#333333;}
.reminder-select.mat-form-field-appearance-legacy .mat-form-field-wrapper{padding-bottom:0;}
.modal-input .reminder-select .mat-form-field-infix{width:100px;}
.modal-input .reminder-select.mat-form-field-appearance-legacy .mat-form-field-underline{display:none;}
.modal-input mat-form-field.reminder-select{flex:unset;}
.modal-input .reminder-select .mat-form-field-label-wrapper {top:-15px;padding-top:15px;right:0;text-align:right;}
.modal-input .reminder-select .mat-select-arrow-wrapper .mat-select-arrow{margin-left:8px;border-left:0px solid transparent;border-right:0px solid transparent;border-top:0px solid;background: url(assets/images/arrow.svg) no-repeat 0 0;background-size:6px 10px;width:6px;height:10px;transform: rotate(90deg);-webkit-transform: rotate(90deg);-moz-transform: rotate(90deg);}
.reminder-select-panel.mat-primary .mat-option.mat-selected:not(.mat-option-disabled){color:#002154;}
.reminder-select.mat-form-field.mat-focused .mat-form-field-label{color:#002154;}
/* .modal-input .reminder-select .mat-form-field-label-wrapper{top:0em;padding-top:0em;}
.modal-input .reminder-select.mat-form-field-appearance-legacy .mat-form-field-label{top:0;} */

.mat-option,
.mat-option.mat-active{color:#002154;}

.reminder-select.mat-form-field.mat-form-field-hide-placeholder .mat-form-field-label-wrapper .mat-form-field-label.mat-empty.mat-form-field-empty{display:block;}
.reminder-select.mat-form-field.mat-form-field-hide-placeholder .mat-form-field-label-wrapper .mat-form-field-label{display:none;}

/* reminder select panel css ends */


.notify-settings-icon svg{width:16px;}
.delete-modal.onetime-access-modal .modal-text{background-color:unset;padding:1.5rem 1.5rem .5rem;margin:1rem 0 0 0;font-weight:400;}
.app-card-name{display:block;margin:0;text-align:center;}
.app-card-nameicon {background-color:#fff;padding:1rem;border-radius:160px;margin:0 auto 1rem auto;display:block;width:60px;height:60px;}
.app-card-nameicon svg{width:18px;}
.app-card-name span{color:#03071E;display:block;border-bottom:0px solid #e0e5ec;padding:0 0 .3rem 0;margin:0 0 0 0;font-size:16px;font-weight:500;}
.app-card-time {margin-bottom:0;font-size:13px;font-weight:500;background-color:#eefff2;padding:.5rem 1rem;}
.app-card-time:nth-child(2){background-color:#FFF1F1;color:#D62828;}
.onetime-access-modal .app-card-info{padding:1rem;justify-content:center;flex-direction:column;align-items:center;}
.modal-body-text{font-size:14px;font-weight:400;text-align:center;margin:1rem 0;}
.modal-id-text{font-style:italic;display:inline-block;padding:0 4px;color:#464748;font-weight:500;}
.onetime-access-modal .cancel-btn{background-color:#F3F4FA;color:#8D99AE;}

.notification-settings-wrapper{padding:2rem;background-color:#ffffff;margin:0 0 1.3rem 0;border-radius:0 4px 4px 4px;border:1px solid #e9ecef;}
.notification-settings-box{margin:0 0 2rem 0;}
.notification-settings-box:last-child{margin:0 0 0 0;}
.notification-settings-box-top{display:flex;justify-content:space-between;padding:0 0 0 0;border-bottom:1px solid #e6e6e6;margin:0 0 1.5rem 0;}
.notification-settings-title{font-size:15px;font-weight:500;padding:0 0 0 0;margin:0 0 .5rem 0;color:#002154;}
.notification-settings-status{font-size:14px;font-weight:400;color:#03071E;}
.apply-btn{margin:0 0;color:#ffffff;background-color:#0E48CA;padding:.6rem 1rem .5rem;border-radius:4px;font-size:13px;font-weight:500;}

.notification-settings-box .mat-checkbox{margin-right:.5rem;}
.notification-settings-box label.mat-checkbox-layout{margin-bottom:0;text-align:center;display:flex;}
.notification-settings-box .mat-checkbox-frame{border-width:1px;border-color:#707070;background-color:#ffffff;}
.notification-settings-box .mat-ripple.mat-checkbox-ripple.mat-focus-indicator{display:none;}
.notification-settings-box .mat-checkbox-layout .mat-checkbox-label{line-height:unset;white-space:normal;font-size:13px;font-weight:500;color:#2B2D42;text-align:left;}
.notification-settings-box .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.notification-settings-box .mat-checkbox-checked.mat-accent .mat-checkbox-background{background-color:#E63946;}
.notification-settings-box .mat-checkbox-label b{color:#E63946;font-weight:500;}
.notification-settings-box .mat-checkbox-inner-container{width:14px;height:14px;margin:0 .5rem 0 0;position:relative;top:2px;}
.notification-settings-box .mat-slide-toggle-label{margin:0;position:relative;bottom:5px;}

.notification-settings-box-top .mat-slide-toggle.mat-checked .mat-slide-toggle-bar{background-color:rgb(103 146 215 / 30%);}
.notification-settings-box-top .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {background-color:#6792D7;}
.notification-settings-box-top .mat-slide-toggle.mat-checked .mat-ripple-element{background-color:#D62828;}

.notification-settings-list-section{display:flex;margin-bottom:0rem;}
.notification-settings-list-section ul{min-width:100%;margin-bottom:0;display:flex;flex-wrap:wrap;}
.notification-settings-list-section ul li{width:50%;display:flex;margin-bottom:1rem;}
.notification-settings-list-section ul li:last-child{margin-top:1rem;margin-bottom:0;}
.notification-settings-list-section ul label{font-size:13px;display:flex;align-items:flex-start;margin-bottom:0;color:#002154;}
.notification-settings-list-section ul input{margin:0 .4rem 0 0;}
.notification-settings-list-section .notification-container{margin-top:3px;}

@media screen and (max-width:575px){
  .notification-settings-wrapper{padding:1.25rem;}
  .notification-settings-list-section ul li{width:100%;}
}

.notification-settings-list-section.notification-settings-list-section-disabled-app label,
.notification-settings-list-section.notification-settings-list-section-disabled-email label,
.notification-settings-list-section.notification-settings-list-section-disabled-sms label{color:#d4d1d1;}

.notification-settings-selectall{display:flex;align-items:center;margin:0 0 .5rem 0;color:#002154;}
.notification-settings-selectall-text{font-size:12px;margin:0;}

.notification-tab.mat-tab-group{margin:1rem 0;}
.notification-tab .mat-tab-label.mat-tab-label-active{opacity:1;background-color:#ffffff;
  -webkit-box-shadow: 0 -5px 5px -5px #333;
  box-shadow: 0 -5px 5px -5px #333;
}
.notification-tab .mat-tab-label.mat-tab-label-active .mat-tab-label-content{color:#0E48CA;}
.notification-tab.mat-tab-group.mat-primary .mat-ink-bar,
.notification-tab.mat-tab-nav-bar.mat-primary .mat-ink-bar{background-color:#0E48CA;}
.notification-tab .mat-tab-nav-bar,
.notification-tab .mat-tab-header{border-bottom:0;}

.notification-tab .mat-tab-label.cdk-focused.cdk-mouse-focused{background-color:#ffffff;}
.notification-tab .mat-tab-label.cdk-focused.cdk-mouse-focused .mat-tab-label-content{color:#0E48CA;}
.notification-tab .mat-tab-label{background-color:#e9ecef;margin-right:.5rem;border-radius:4px 4px 0 0;border:1px solid #e9ecef;color:#002154;}
.notification-tab .mat-ripple-element{display:none;}

.mask-input{background-color:#FAFAFA;border:1px solid #DAE2E5;border-radius:2px;padding:0 .5rem;margin-top:.3rem;height:38px;line-height:38px;margin-bottom:1.34375em;}

.app-info-icon {margin:0 .5rem 0 0;width:20px;height:20px;}
.app-info-icon img{height:18px;}

.enclosuresettings-form .modal-input i img.door-icon-width{width:24px;}
.modal-email-icon{width:17.957px;}
.led-icon-width{width:20px;}
.led{display:inline-flex;width:9px;height:9px;border-radius:100px;margin-right:.5rem;}
.led.green{background-color:#14DE4A;}
.led.red{background-color:#FE0000;}
.led.blue{background-color:#0000FE;}
.led.yellow{background-color:#FFFF01;}
.led.pink{background-color:#FF69B4;}
.led.aqua{background-color:#00FFFF;}
.led.white{background-color:#ffffff;border:1px solid #ccc;}
.led.green-dark{background-color:#086c39;}
.led.blue-light{background-color:#6088f4;}
.led.gred-orange{background-color:#ff4500;}

.led-select-panel{display:flex;flex-direction:column;justify-content:center;align-items:center;margin:0 0 1.25rem;}
.led-box{cursor:pointer;}

.setstage-modal-form .modal-radio-group .mat-radio-button{margin:0 1rem 0 0;}

.addenclosure-form .modal-input.enc-disabled mat-form-field{position:relative;}
.addenclosure-form .modal-input.enc-disabled mat-form-field::before{position:absolute;content:"";width:100%;height:100%;background-color:transparent;z-index:1;}

.parcel-btn{margin-left:0rem;font-size:13px;border-radius:4px;display:flex!important;align-items:center;color:#002154;}
.parcel-btn-icon{margin-right:.3rem;}
.parcel-btn-icon.material-icons{color:#0E48CA;font-size:18px;}
.parcel-btn-icon svg{height:10px;}

.app-card-encllistinfo-wrap{padding:.75rem 1rem;background-color:#f7f9fc;border-radius:4px;
  -webkit-box-shadow:inset 0 0 6px 0px #e0e3e6;
  box-shadow:inset 0 0 6px 0px #e0e3e6}
.app-card-encllist-info{display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;padding:0rem;margin-bottom:0;}
.app-card-encllist-info span{width:100%;padding:.3rem 0rem;margin:0 0 0 0;text-align:left;border-radius:4px;}

.app-card-encllist-info label{flex-wrap:wrap;}
.app-card-encllist-info .app-info-icon{width:auto;display:flex;}

.border-bottom{border-bottom:1px solid #DAE2E5;}
.border-top-none{border:none;}

.enclosurelist-card .app-card-body.border-top-none{border:none;}

.material-icons {
  font-family: 'Material Icons', sans-serif !important;
}


.dis_enc_id{background-color:#FAFAFA;border:1px solid #DAE2E5;border-radius:2px;padding:0 .5rem;margin-top:.3rem;color:#2B2D42;font-size:14px;font-weight:500;height:38px;line-height:36px;margin-bottom:1.34375em;}

.modal-logout-title{margin:2rem 0 .55rem;font-size:20px;color:#002154;text-align:center;}
.modal-logout-text{padding:0 0;font-size:15px;font-weight:400;color:#002154;text-align:center;}
.modal-logout-bottom{display:flex;justify-content:center;align-items:center;margin:1rem 0 2rem;}
.btn-logout{padding:.3rem 0rem;width:70px;border-radius:4px;border-width:0px;border-style:solid;font-size:13px;font-weight:500;}
.yes{background-color:#0E48CA;color:#ffffff;border:1px solid #0E48CA;}
.cancel{background-color:#FFFFFF;color:#0E48CA;border:1px solid #0E48CA;}

#map,.map{width:100% !important;height:calc(100vh - 250px) !important;position:relative !important;}
.leaflet-marker-pane img:nth-child(1){margin-left:-52px!important;margin-top:-84px!important;}
.leaflet-marker-pane img:nth-child(2){margin-top:-84px!important;}
.leaflet-marker-pane img:nth-child(3){margin-top:-34px!important;}


.delivery-order-form{display:flex;flex-wrap:wrap;background-color:#ffffff;border-radius:6px;padding:1.5rem .5rem 0;margin-top:0;}
.del-ord-form-control{width:calc(50% - 0rem);display:flex;flex-direction:column;padding:0 1rem;}
.del-ord-form-control label{font-weight:500;font-size:13px;margin-bottom:0;color:#d62828;display:flex;align-items:center;}
.del-ord-form-control label img{margin:0 .5rem 0 0;}
.del-ord-form-btn{display:flex;align-items:center;font-weight:400;font-size:13px;background-color:#d62828;color:#ffffff;padding:.3rem .625rem;border-radius:4px;margin-bottom:2rem;}
.del-ord-form-btn em{font-size:16px;margin:0 .5rem 0 0;}
.del-ord-form-btn:hover{background-color:#03071E;}
.delivery-request-title{font-size:16px;font-weight:600;background-color:#FAFAFA;padding:1.1rem 1.35rem;border-radius:6px 6px 0 0;}

.del-ord-form-control .mat-form-field-appearance-standard .mat-form-field-flex{padding-top:0;}
.del-ord-form-bottom{padding:0 1rem;display:flex;justify-content:center;align-items:center;}
.del-ord-form-control input.mat-input-element{font-size:13px;font-weight:500;color:#000;}
.del-ord-form-control .mat-form-field-underline{display:none;}
.del-ord-form-control .mat-form-field-infix{background-color:#F7F9FC;border-top:0;padding:.4em .8em .6em;border-radius:4px;margin-top:.5rem;}
.del-ord-form-control .mat-form-field{margin-bottom:0;}

@media screen and (max-width:575px){
  .del-ord-form-control{width:calc(100% - 0rem);}
}

.drone-box{margin-top:1.2rem;display:flex;}
.drone-box-wrap{background-color:#ffffff;border-radius:6px;width:100%;}
.drone-box-cover{padding:2rem 2rem;position:relative;}
.drone-box-infowrap{display:flex;flex-wrap:wrap;justify-content:space-between;}
.drone-box-info{font-size:13px;font-weight:500;border-bottom:2px solid #F5F7FA;padding:0 0rem .5rem 0;color:#03071E;width:calc(50% - .5rem);}
.drone-box-info strong{align-items:center;font-weight:500;display:block;color:#D62828;padding:0 0 .5rem;}
.drone-box-info strong img{margin:0 .5rem 0 0}
.drone-box-titlewrap{margin-top:1rem;}
.drone-box-title{padding:1rem;font-size:14px;font-weight:400;position:relative;display:flex;align-items:flex-start;flex-wrap:wrap;background-color:#f7f9fc;margin-bottom:1rem;}
.drone-box-title .drone-box-titleinfo{width:100%;font-size:13px;font-weight:400;color:#03071e;padding:.2rem 0 0 1.5rem;}
.drone-box-subtitle{display:flex;align-items:center;}
.drone-box-subtitle .material-icons{margin:0 .3rem 0 0;font-size:18px;}
.drone-box-subtitle strong{font-weight:500;}

@media screen and (max-width:991px){
  .drone-box-cover{padding:1.5rem 1rem;}
}
@media screen and (max-width:480px){
  .drone-box-info{width:calc(100% - 0rem);}
}

.delete-data{color:#ffffff;cursor:pointer;background-color:#d62828;padding:.3rem .75rem;border-radius:4px;font-size:13px;font-weight:500;display:inline-flex;margin-bottom:1rem;}
.release-data,
.scan-data,
.parcel-data{margin-bottom:0rem;border-radius:4px;display:flex;align-items:center;justify-content:center;font-size:13px;font-weight:500;padding:.65rem 0;cursor:pointer;}
.release-data,
.scan-data{color:#ffffff;}
.release-data{background-color:#4caf50;}
.scan-data{background-color:#4fc3f7;}
.parcel-data{color:#ffffff;background-color:#d62828;}
.request-info{color:#ec407a}
.reject-info{color:#d32f2f}
.release-info{color:#009688}
.waiting-info{color:#ffa726}
.notopen-info{color:#f55305}
.accept-info{color:#5c6bc0;}
.delivered-info{color:#4caf50;}
.scan-info{color:#3799ff;}
.error-info{color:#d62828}
.notscan-info{color:#f55305;}
.notscan-info i{position:relative;}
.notscan-info i::before,
.notscan-info i::after{position:absolute;content:'';width:2px;height:10px;
-moz-transform: rotate(-90deg);
-webkit-transform: rotate(-90deg);
-o-transform: rotate(-90deg);
-ms-transform: rotate(-90deg);
transform: rotate(-90deg);
}
.notscan-info i::before{background-color:#f7f9fc;left:8px;top:2px;}
.notscan-info i::after{background-color:#f55305;left:8px;top:4px;}

.drone-box-bottom-info .material-icons{font-size:18px;margin:0 .3rem 0 0;}
.drone-box-topright-delete{position:absolute;right:1rem;top:1rem;cursor:pointer;}
.drone-box-topright-delete .material-icons{font-size:14px;color:#d62828;background-color:#FFE2E5;width:24px;height:24px;border-radius:4px;text-align:center;line-height:24px;}

.drone-nodata-box{padding:2rem;background-color:#ffffff;}
.drone-nodata-cover{display:flex;justify-content:center;align-items:center;flex-direction:column;}
.drone-nodata-cover .material-icons{font-size:48px;color:#021D59;}
.drone-nodata-cover .drone-nodata-text{font-size:22px;font-weight:600;color:#021D59;}

.mapboxgl-marker{background-repeat:no-repeat;}

.markerstart {background-image:url('assets/images/map-enclosure.svg');background-repeat:no-repeat;background-size:26px auto;width:38px;height:42px;cursor:pointer;background-color:transparent;}
.markerend {background-image:url('assets/images/drone-map-location.svg');background-repeat:no-repeat;background-size:26px auto;width:30px;height:40px;cursor:pointer;background-color:transparent;}

.view-btn{display:flex;align-items:center;margin:0 .5rem 0 auto;background-color:#6792d71f;color:#6792D7;border-radius:4px;font-size:13px;font-weight:500;padding:.21rem .725rem  .22rem .625rem;}
.view-btn .material-icons{font-size:16px;margin:0 .3rem 0 0;}

.duration-modal-title{font-size:18px;font-weight:500;text-align:center;padding:3rem 2rem 0rem;color:#333;background-color:#ffffff;border-radius:4px 4px 0 0;}
.duration-form{display:flex;flex-direction:column;justify-content:center;align-items:center;}
.duration-modal-username{font-size:14px;font-weight:500;margin:0 0 1rem 0;}
.duration-modal-info{text-align:center;}
.duration-modal-text{font-size:13px;font-weight:400;text-align:center;margin:.5rem 0 1.5rem;}
.duration-modal-date{background-color:#f5f5f5;padding:.17rem 1rem;border-radius:4px;margin:.5rem;font-size:13px;font-weight:500;}

.warning-alert{display:flex;padding:.5rem 1rem;margin:1rem 0 0;background-color:#f8f3d6;border-radius:4px;border:1px solid #d8cd85;color:#967132;}
.warning-alert .material-icons{font-size:18px;margin:0 .3rem 0 0;}
.warning-alert-text{margin:0 0 0 0;font-size:13px;padding-top:0.1rem;}
.warning-alert-text strong{margin:0 .2rem 0 0;font-weight:600;}

.app-forward-btn{position:absolute;right:1rem;display:flex;align-items:center;font-size:13px;font-weight:500;top:0;padding:.3rem .65rem .2rem .45rem;background-color:#cde8f5;color:#4480ae;border-radius:4px;}
.app-forward-btn span.material-icons{color:#4480ae;margin:0 .2rem 0 0;}
.app-forward-btn span img{height:12px;}
.enclosurelist-card-top .app-card-title span.app-forward-btn-text{/*width:60px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;display:inline-block;*/color:#4480ae;font-size:13px;font-weight:500;line-height:18px;}
@media screen and (max-width:1600px) {
  .app-forward-btn{position:relative;right:0rem;}
}

.forward-modal.nsm-dialog{max-width:420px;}
.modal-forward-box{padding:1rem;font-size:14px;}
.forward-block{display:flex;flex-direction:column;padding:0 0 0 0;margin:0 0 0 0;}
.forward-encl-title{display:flex;font-weight:500;color:#03071e;}
.forward-encl-subtitle{font-weight:500;color:#8D99AE;margin:0 .5rem 0 0;width:100px;}
.forward-encl-subtext{flex:1;}
.forward-date-box{padding:0rem;border-radius:4px;margin:0 0 0 0;display:flex;justify-content: space-between;}
.forward-date{display:flex;flex-direction:column;flex:1;}
.forward-date-title{font-weight:14px;font-weight:500;}
.forward-date-wrap{display:flex;align-items:center;}
.forward-date-wrap .material-icons{font-size:16px;margin:0 .5rem 0 0;color:#bdbdbd;}
.forward-date .forward-date-info{padding:.2rem .65rem;margin:.5rem 0 0 0;font-weight:500;font-size:13px;}
.forward-start-date .forward-date-info{background-color:#EEFFF2;margin-right:.5rem;}
.forward-end-date .forward-date-info{background-color:#FFF1F1;}
.forward-start-date .forward-date-title strong{color:#14DE4A;}
.forward-end-date .forward-date-title strong{color:#D62828;}
.forward-email-block{position:relative;background-color:#FAFAFA;border-radius:4px;padding:1rem;text-align:center;margin:0 0 3rem 0;}
.forward-email-block::before{position:absolute;content:'';background-color:#e9ecef;width:100%;height:1px;bottom:-1rem;left:0;}
.forward-email-block .forward-encl-title{display:unset;}
.forward-email-block .forward-encl-subtitle{display:flex;align-items:center;justify-content:center;width:auto;}
.forward-email-block .forward-encl-subtitle img{width:14px;margin:0 .5rem 0 0;}
@media screen and (max-width:575px){
  .forward-block,
  .forward-date-box{flex-direction:column;}
  .forward-start-date .forward-date-info{margin-right:0rem;}
  .forward-start-date {margin-bottom:1rem;}
}

.table-btn-wrap{display:flex;align-items:center;}
.btn-table{display:flex;align-items:center;padding:.2rem .25rem;margin:0 .5rem 0 0;}
.btn-table .material-icons{font-size:16px;margin-top:0;}
.svg-icon{display:flex;height:16.38px;margin-top:0;}

.forwarddeliverylist-card .app-card-title .forward-delivery-btn-wrap .btn-table{margin:0 0 0 .5rem;}
.forwarddeliverylist-card .app-card-title .forward-delivery-btn-wrap span.material-icons{font-size:16px;margin-top:0;}
.forward-delivery-btn-wrap span.svg-icon{margin-top:0;}

.search-filter {width:100%;border:1px solid #DAE2E5;border-radius:4px;background-color:#f7f9fc;padding:0 .5rem;margin:0 0 1rem 0;}
.search-filter .mat-form-field-infix{display:flex;border-top:none;}
.search-filter input{margin-left:.5rem;font-size:13px;}
.search-filter.mat-form-field-appearance-standard .mat-form-field-flex{padding:0 0 0 0;}
.search-filter .mat-form-field-underline{display:none;}
.search-filter .mat-form-field-wrapper{padding-bottom:0;}
.search-filter .material-icons{font-size:18px;font-weight:500;color:#87898c;}
.search-filter input::-webkit-input-placeholder{font-size:13px;}
.search-filter input::-moz-placeholder{font-size:13px;}
.search-filter input:-ms-input-placeholder{font-size:13px;}
.search-filter input:-moz-placeholder{font-size:13px;}

.search-filter input:-webkit-autofill,
.search-filter input:-webkit-autofill:hover,
.search-filter input:-webkit-autofill:focus
.search-filter input:-webkit-autofill{
  border:0px solid rgba(0, 0, 0, 0.05) !important;
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px #f9f9f9 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.app-paginator button:disabled{background:transparent;}
.app-paginator .mat-form-field.mat-focused .mat-form-field-ripple{background-color:#D62828;}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled){color:#002154;}
.app-paginator .mat-form-field.mat-focused.mat-primary .mat-select-arrow{color:#D62828;}

.modal.warning-modal{max-width:340px;}
.warning-modal .nsm-body{padding:0 0 2rem;background-color:#ffffff;border-radius:6px;}
.warning-modal-title{display:flex;justify-content:center;align-items:center;flex-direction:column;font-size:18px;font-weight:500;padding:2rem 0 1rem;color:#021D59;}
.warning-modal-title .material-icons{font-size:36px;line-height:46px;color:#FFA426;}
.warning-modal-text{margin-bottom:0;font-size:14px;padding:.75rem 1.35rem;color:#021D59;display:flex;flex-direction:column;align-items:center;}
.warning-modal-info{color:#8a6d3b;background-color:#fcf8e3;border:1px solid #faebcc;border-radius:4px;padding:.1rem .4rem;margin:.3rem 0;line-height:24px;}
.warning-modal-info-highlight{display:inline-flex;}
.warning-modal-name-text{color:#03071e;}

.parcel-view-btn{display:flex;align-items:center;margin:0 .5rem 0 0;background-color:#E1F0FF;color:#6792D7;border-radius:4px;font-size:13px;font-weight:500;padding:.15rem .325rem;}
.parcel-view-btn .material-icons{font-size:16px;margin:0 0 0 0;}
.parcel-view-btn:hover{color:#3799ff;}

.orderstatus-msg{font-weight:500;padding:.2rem .5rem;background-color:#f5f5f5;border:1px solid #eee;font-size:12px;border-radius:4px;}
.orderstatus-info{font-size:13px;color:#495057;padding:0 0 0 0;display:none;}

.viewparcel-box-block{display:flex;flex-wrap:wrap;padding:1rem .5rem;background-color:#eee;border-radius:0 0 6px 6px;}
.viewparcel-box{width:calc(50% - 1rem);margin:0 .5rem 1rem;padding:.5rem .7rem 0;background-color:#fff;border-radius:4px;}
.viewparcel-box-title{font-size:13px;font-weight:600;color:#252C33;display:flex;align-items:center;}
.viewparcel-box-title img,.viewparcel-box-title .material-icons{margin:0 .5rem 0 0;}
.viewparcel-box-title .material-icons{color:#adb0bf;font-size:18px;}
.viewparcel-box-info{font-size:13px;font-weight:400;color:#8D99AE;padding:.2rem 0 .6rem 0;display:flex;}
.viewparcel-status{display:flex;flex-direction:column;width:100%;padding:.5rem 1rem;background-color:#fff;border-radius:4px;margin: 0 .5rem;}
.viewparcel-status .viewparcel-status-box-info{padding:.2rem 0 0 0;font-size:13px;font-weight:400;}
.viewparcel-status-box-title{font-size:13px;font-weight:500;display:flex;align-items:center;}
.viewparcel-status-box-title .material-icons{margin:0 .3rem 0 0;font-size:18px;}
@media screen and (max-width:575px){
  .viewparcel-modal.nsm-dialog.nsm-centered{align-items:flex-start;overflow-x:hidden;overflow-y:auto;}
  .viewparcel-box{width:calc(100% - 0rem);}
}

.notification-container{display:block;position:relative;padding-left:20px;margin-bottom:0px;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width:14px;height:14px;}
.notification-container input{position:absolute;opacity:0;cursor:pointer;height:0;width:0}
.checkmark{position:absolute;top:0;left:0;height:14px;width:14px;background-color:#eee;border-radius:3px;}
.notification-container:hover input ~ .checkmark{background-color:#ccc}
.notification-container input:checked ~ .checkmark{background-color:#6792D7}
.checkmark:after{content:"";position:absolute;display:none}
.notification-container input:checked ~ .checkmark:after{display:block}
.notification-container .checkmark:after{left:4px;top:2px;width:5px;height:9px;border:solid #fff;border-width:0 2px 2px 0;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg)}

.date-block{position:relative;background-color:#fafafa;border:1px solid #dae2e5;border-radius:2px;min-height:38px;line-height:38px;padding:0 .5rem;font-size:14px;margin-top:.3rem;}
.date-notinserted{color:#a7a6a6;}
.date-inserted{position:absolute;background-color:#fafafa;left:0;width:calc(100% - 1rem);right:0;margin:0 auto;}

/* .cdk-overlay-container{overflow-y:auto;}
.cdk-overlay-pane{max-height:unset;}
@media screen and (max-width:600px){
  ::ng-deep bs-datepicker-container{
      transform: translate3d(0px, 647px, 0px)!important;
  }
} */

.metrics-modal.nsm-dialog{max-width:320px;}
.metrics-modal .modal-input{margin-bottom:1rem;padding-bottom:1rem;border-bottom:1px solid #DAE2E5;align-items:center;}
.metrics-modal .modal-input:last-child{margin-bottom:0;padding-bottom:0;border-bottom:none;}
.metrics-modal .app-info-icon{display:inline-block;text-align:center;}
.metrics-modal .modal-radio{display:flex;flex-direction:column;}
.metrics-modal .modal-radio-group{display:flex;flex-direction:column;margin:0 0 1rem 0;}
.metrics-modal .metrics-info{margin:.5rem .5rem 0 0;font-size:13px;font-weight:600;color:#0E48CA;}
.metrics-modal a:not([href]):not([tabindex]),
.metrics-modal a:not([href]):not([tabindex]):focus,
.metrics-modal a:not([href]):not([tabindex]):hover {color:#0E48CA;}

.modal-radio-group .mat-radio-container,
.modal-radio-group .mat-radio-outer-circle,
.modal-radio-group .mat-radio-inner-circle{width:14px;height:14px;}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{background-color:#6792D7;}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {border-color:#6792D7;}
.modal-radio-buttons{margin:0 0 0 0;display:flex;justify-content:space-between;}
.modal-radio-btn{line-height:32px;font-size:13px;font-weight:500;text-align:center;min-width:110px;min-height:32px;color:#ffffff;border-radius:100px;}
.modal-radio-save-btn{background-color:#0E48CA;}
.modal-radio-cancel-btn{color:#0E48CA;border:1px solid #0E48CA;}
.modal-radio-group .mat-radio-button{font-size:16px;font-weight:400;margin:0 0 0 0;color:#002154;}

@media screen and (max-width:575px){
  .metrics-modal .modal-input{flex-direction:row;}
}


.modal-address-title label{padding-bottom:1rem;color:#021D59;}
.modal-input.modal-address-padding-bottom label{padding-top:0;color:#021D59;}

.address-add-enclosure .addenclosure-form .modal-input.add-input-50{width:calc(50% - .5rem);}
.address-add-enclosure .modal-form.addenclosure-form{display:flex;flex-wrap:wrap;justify-content:flex-start;}
.address-add-enclosure .modal-margin-right{margin-right:10px}
.address-add-enclosure.addenclosure-modal.nsm-dialog{max-width:800px;}
.address-wrap{display:flex;flex-wrap:wrap;justify-content:space-between;}
.address-add-user-btn img{width:18px;}
.address-add-user-btn .modal-btn.add-btn{position:unset;background-color:transparent;border:none;width:unset;height:unset;}
.address-add-user-btn {position:absolute;top:.5rem;right:2rem;background-color:#e63946;width:30px;height:30px;display:flex;align-items:center;justify-content:center;border-radius:100px;}
.address-add-enclosure .addenclosure-form .modal-select .mat-form-field{position:relative;top:-7px;flex:unset;}
.address-add-enclosure .addenclosure-form .modal-input{width:calc(33.33333% - .5rem);}
@media screen and (max-width:575px) {
  .address-add-enclosure .addenclosure-form .modal-input{width:calc(50% - .5rem);}
}
@media screen and (max-width:480px) {
  .address-add-enclosure .addenclosure-form .modal-input,
  .address-add-enclosure .addenclosure-form .modal-input.add-input-50{width:calc(100% - 0rem);}
  .address-add-enclosure .addenclosure-form .modal-select .mat-form-field{top:0px;}
}

@media screen and (max-width:575px) {
  .main.mx-2 {margin-left:0!important;margin-right:0!important;}
}

.fault-block{width:100%;background-color:#FFE9E9;display:flex;flex-direction:column;padding:.5rem .825rem .7rem;margin:.75rem 0 0 0;border-radius:4px;}
.fault-title{color:#D62828;font-weight:600;font-size:15px;}
.fault-title img{margin:0 .3rem 0 0;}
.fault-info{line-height:16px;}
.fault-text{color:#002154;font-weight:500;font-size:13px;}

.enc_fault{position:relative;}
.enc_fault::before{position:absolute;content:'';top:0;left:0;width:100%;height:100%;border:.125rem solid #0E48CA;border-radius:6px;}
.enc_fault .app-card-top,
.enc_fault .app-card-encllistinfo-wrap,
.enc_fault .app-card-body,
.enc_fault .app-card-doorstatus-wrap{z-index:1;position:relative;}
.app-card-title-wrap{display:flex;align-items:flex-end;}
.app-card-title-wrap span{margin:0 0 0 .5rem;}
.app-card-doorstatus-wrap{display:flex;margin:0 auto;justify-content:space-between;align-items:center;width:calc(100% - 2rem);padding:.625rem .725rem;border-radius:4px;box-shadow:0px -1px 6px 0px rgb(0 0 0 / 11%);-webkit-box-shadow:0px -1px 6px 0px rgb(0 0 0 / 11%);}
.app-card-doorstatus-title{font-size:14px;display:flex;color:#021D59;}
.app-card-doorstatus-subtitle{font-size:14px;font-weight:400;color:#8D99AE;margin:0 0 0 .5rem;display:flex;align-items:center;}
.app-doorstatus-info{font-weight:500;display:flex;align-items:center;}
.app-doorstatus-info img{margin:0 .3rem 0 0;height:16px;}
.app-doorstatus-info.green{color:#83E349;}
.app-doorstatus-info.gray{color:#A6A6A6;}
.app-doorstatus-info.red{color:#E63946;}
.app-card-doorstatus-inner{display:flex;}

.led_closed{background-color:#dee2e6;color:#ffffff;width:37px;height:22px;line-height:22px;padding:0 0 0 .5rem;border-radius:160px;position:relative;font-size:10px;font-weight:500;display:flex;}
.led_closed::after{position:absolute;content:'';width:16px;height:16px;background-color:#ffffff;border-radius:160px;top:3px;left:2px;}

.led_open{background-color:rgba(20, 222, 74, 0.16);color:#14DE4A;width: 37px;height:22px;line-height:22px;padding:0 .5rem 0 0;border-radius:160px;position:relative;font-size:10px;font-weight:500;display:flex;justify-content:flex-end;}
.led_open::after{position:absolute;content:'';width:16px;height:16px;background-color:#14DE4A;border-radius:160px;top:3px;right:2px;}

.error-infobox{padding:.5rem 1rem;display:flex;align-items:center;background-color:#fce8e8;}
.error-infobox .fault-info{font-size:13px;margin:0 0 0 .75rem;}

.status-onoff{margin:0 0 0 .25rem;}

.mat-paginator button:disabled{background:transparent;}

.door_statusclosed{background-color:#83e3492b;color:#83E349;width:37px;height:22px;line-height:22px;padding:0 0 0 .5rem;border-radius:160px;position:relative;font-size:10px;font-weight:500;display:flex;}
.door_statusclosed::after{position:absolute;content:'';width:16px;height:16px;background-color:#83E349;border-radius:160px;top:3px;left:2px;}

.door_statusopen{background-color:#fce8e8;color:#e63946;width: 37px;height:22px;line-height:22px;padding:0 .5rem 0 0;border-radius:160px;position:relative;font-size:10px;font-weight:500;display:flex;justify-content:flex-end;}
.door_statusopen::after{position:absolute;content:'';width:16px;height:16px;background-color:#D62828;border-radius:160px;top:3px;right:2px;}

.door_statusdisabled{background-color:#e9ecef;color:#b7b9ba;width: 37px;height:22px;line-height:22px;padding:0 .5rem 0 0;border-radius:160px;position:relative;font-size:10px;font-weight:500;display:flex;justify-content:flex-end;}
.door_statusdisabled::after{position:absolute;content:'';width:16px;height:16px;background-color:#b7b9ba;border-radius:160px;top:3px;left:2px;}

.percentage-box {width:20px;height:.38px;margin:0px;}
.battery-percentage-block{margin:0 1rem 0 0;position:relative;display:flex;background-image:url('assets/images/available-height-bg.svg');background-repeat:no-repeat;background-size:40px auto;width:40px;height:43px;padding-top:6px;}
.battery-percentage-block::before{position:absolute;content:'';width:calc(100% - 2px);margin-left:auto;margin-right:auto;background-color:rgba(36, 43, 50, .65);}
.battery-percentage-block battery-percentage,
.battery-percentage-block battery-percentage .percentage-box{width:calc(100% - 2px);-moz-transform: rotate(180deg); -webkit-transform: rotate(180deg);-o-transform: rotate(180deg);-ms-transform: rotate(180deg);transform: rotate(180deg);}
.battery-percentage-block battery-percentage .percentage-box.white{background-color:rgba(36, 43, 50, 0)!important;}
.battery-percentage-block battery-percentage .percentage-box.gray{background-color:rgba(36, 43, 50, .28)!important;}

.parcel-modal-topcover{display:flex;}
.parcel-modal-topcover .modal-title-encl{width:50%;}
@media screen and (max-width:575px) {
  .parcel-modal-topcover{flex-wrap:wrap;}
.parcel-modal-topcover .modal-title-encl{width:100%;}
}

/* Access Control Css Starts */
.access-control-modal{margin:20px;display:flex;flex-wrap:wrap;gap:20px;}
.access-modal-input{display:flex;flex-direction:column;width:calc(50% - 10px);}
.modal-input.fullwidth{width:100% !important;}
.access-modal-input.fullwidth{width:100%;}
.access-modal-input label{margin-bottom:3px;font-size:13px;font-weight:500;color:#002154;}
.access-modal-input label sup{color:#e63847;}
.access-modal-input .mat-form-field-wrapper{padding:0;}
.access-modal-input .mat-form-field-infix{border-top:0;padding:0;}
.access-modal-input .mat-select-arrow{margin:10px 4px -10px;}
.access-modal-input .mat-select-placeholder,
.access-modal-input .mat-select-value-text,
.access-modal-input .mat-input-element{font-size:14px;}
.access-modal-input .mat-form-field-underline{display:none;}
.access-modal-input .mat-form-field-label-wrapper{display:none;}
.access-modal-input .mat-form-field-appearance-fill .mat-form-field-flex{padding:0.5em 0.75em;background-color:#FAFAFA;border:1px solid #dae2e5;border-radius:4px;}
.access-modal-input .mat-datepicker-toggle{position:absolute;right:-11px;top:-11px;bottom:0;}
.access-modal-input .mat-icon-button svg{width:20px;height:20px;}
.access-modal-input .mat-form-field .mat-error{margin:17px 0 0 -10px;font-size:11px;}
.access-modal-input .mat-error{font-size:11px;}
.access-control-modal .add-btn{width:fit-content;margin-top:10px;}
.blue-btn{width:28px;height:28px;display:flex;justify-content:center;align-items:center;background-color:#e1f0ff;margin-right:.5rem;}
.blue-btn .material-icons{font-size:16px;color:#6792D7;}
.red-btn{width:28px;height:28px;display:flex;justify-content:center;align-items:center;background-color:#fce8e8;margin-right:.5rem;}
.yellow-btn{width:28px;height:28px;display:flex;justify-content:center;align-items:center;background-color:#f8f3d6;margin-right:.5rem;}
.yellow-btn .material-icons{font-size:16px;color:#967132;}
.btn.disabled.blue-btn img,
.btn:disabled.blue-btn img,
.btn.disabled.red-btn img,
.btn:disabled.red-btn img {filter:grayscale(100%);}
.video-btn.delete-btn{padding:.3rem;}
.video-btn.delete-btn span,
.history-btn.delete-btn span{margin-left:0;}
.vjs-default-skin{width:100% !important;height:auto !important;}
.history-btn.delete-btn{padding:.3rem;min-width:0px;line-height:24px;margin-right:.5rem;}

.viewenclosure-form{display:flex;flex-wrap:wrap;background-color:#eee;padding:1rem .5rem 0;border-radius:0 0 6px 6px;}
.viewenclosure-form .modal-input{width:calc(50% - 1rem);background-color:#ffffff;margin:0 .5rem 1rem;padding:.5rem .7rem 0;border-radius:4px;box-shadow:0px 0px 6px 0px rgba(50, 50, 50, 0.07);-webkit-box-shadow:0px 0px 6px 0px rgba(50, 50, 50, 0.07);-moz-box-shadow:0px 0px 6px 0px rgba(50, 50, 50, 0.07);}
.viewenclosure-form .modal-input.description-box{width:calc(100% - 1rem);max-height:140px;overflow:auto;}
.modal.viewenclosure-modal.nsm-dialog{max-width:600px;}
.viewenclosure-form .modal-input label{padding:0;color:#252C33;font-weight:600;display:flex;align-items:center;}
.viewenclosure-form .modal-input label .material-icons{font-size:18px;margin:0 .5rem 0 0;color:#8492a0;}
.viewenclosure-form .modal-input input.mat-input-element,
.viewenclosure-form .modal-input textarea.mat-input-element{background-color:transparent;border:none;margin-top:0;}
.viewenclosure-form .modal-input input.mat-input-element{min-height:unset;height:unset;line-height:unset;}
.viewenclosure-form .modal-input label{font-size:12px;font-weight:600;color:#252C33;}
.viewenclosure-form .modal-input input.mat-input-element,
.viewenclosure-form .modal-input textarea.mat-input-element,
.viewenclosure-form .mat-form-field-wrapper{padding-bottom:0;}
.viewuser-form{display:flex;flex-wrap:wrap;background-color:#e9ecef;padding:1rem .5rem 0;border-radius:0 0 6px 6px;}
.viewuser-form .modal-input{width:calc(50% - 1rem);background-color:#ffffff;margin:0 .5rem 1rem;padding:.5rem .7rem 0;border-radius:4px;box-shadow:0px 0px 6px 0px rgba(50, 50, 50, 0.07);-webkit-box-shadow:0px 0px 6px 0px rgba(50, 50, 50, 0.07);-moz-box-shadow:0px 0px 6px 0px rgba(50, 50, 50, 0.07);}
.viewuser-access .modal-input{min-height:58px;}
.modal.viewuser-modal.nsm-dialog{max-width:600px;}
.viewuser-form .modal-input label{padding:0;color:#252C33;font-weight:600;display:flex;align-items:center;}
.viewuser-form .modal-input label .material-icons,
.viewuser-box-title .material-icons{font-size:18px;margin:0 .5rem 0 0;color:#8492a0;}
.viewuser-form .modal-input input.mat-input-element,
.viewuser-form .modal-input textarea.mat-input-element{background-color:transparent;border:none;margin-top:0;}
.viewuser-form .modal-input input.mat-input-element{min-height:unset;height:unset;line-height:unset;}
.viewuser-box-title{flex:1;margin-bottom:0;display:flex;align-items:center;}
.viewuser-box-subtitle{display:flex;min-height:32px;line-height:unset;align-items:center;}
.viewuser-form .modal-input label,
.viewuser-box-title{font-size:12px;font-weight:600;color:#252C33;}
.viewuser-form .modal-input input.mat-input-element,
.viewuser-form .modal-input textarea.mat-input-element,
.viewuser-box-subtitle{font-size:13px;font-weight:400;color:#8D99AE;padding:.2rem 0 .6rem 0;word-break:break-all;}
.viewuser-form .mat-form-field-wrapper{padding-bottom:0;}
.viewuser-form i,.viewuser-form sup{display:none;}
.delete-enclosure-form{padding:1rem 1.4rem;}
.delete-confirmation{color:#002154;}
.delete-confirmation-cancel{font-size:14px;padding:.3rem 1rem;margin-right:.7rem;border:1px solid #0E48CA;border-radius:4px;color:#0E48CA;}

.info-outlined{position:absolute;top:0;right:0;font-size:0;}
.multi-line-tooltip{background-color:#ffffff;color:#0E48CA !important;border:1px solid #0E48CA;white-space:pre-line;}
.note-access-date{color:#967132;font-size:12px;}
.timezone-access-date{color:#002154;font-size:12px;}
.timezone-access-date span{color:#D62828;}

@media screen and (max-width:575px){
  .viewenclosure-form .modal-input{width:calc(100% - 1rem);}
  .viewuser-form .modal-input{width:calc(100% - 1rem);}
}
/* Access Control Css Ends */

/* Live Video Streaming Css Starts */
.video-streaming-modal.addenclosure-modal.nsm-dialog{max-width:400px;}
.video-container.video-js{width:80%!important;padding-top:45%;}
.video-container.video-js .vjs-big-play-button{top:50%;left:50%;transform:translate(-50%, 50%);}
.timer{font-weight:600;color:#E63946;}
.stream-continuous{position:absolute;top:0;bottom:0;left:0;right:0;margin:auto;text-align:center;color:#ffffff;}
.modal.user-door-image.nsm-dialog{max-width:1280px;}
/* Live Video Streaming Css Ends */